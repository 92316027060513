import { CanActivateFn } from '@angular/router';
import {LoginResponse, OidcSecurityService} from "angular-auth-oidc-client";
import {inject} from "@angular/core";
import {map} from "rxjs";

export const authGuard: CanActivateFn = (route, state) => {
  const oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);
  return oidcSecurityService.checkAuth().pipe(
    map((auth: LoginResponse) => {
      if (!auth.isAuthenticated) {
        oidcSecurityService.authorize();
        return false;
      }
      return true;
    })
  )
};
