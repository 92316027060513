@if (loading) {
  <div id="sm-loader" class="row">
    <div class="col">
      <div id="loader">
        <div class="ls-particles ls-part-1"></div>
        <div class="ls-particles ls-part-2"></div>
        <div class="ls-particles ls-part-3"></div>
        <div class="ls-particles ls-part-4"></div>
        <div class="ls-particles ls-part-5"></div>
        <div class="lightsaber ls-left ls-green"></div>
        <div class="lightsaber ls-right ls-red"></div>
      </div>
    </div>
  </div>
}

@if (!loading) {
  <div class="row mb-3">
    <div class="col-12">
      <mat-tab-group dynamicHeight>
        <mat-tab [label]="'Dev'">
          <div class="row mb-3">
            <div class="col">
              <div class="row mt-4 mb-2">
                <div class="col-12">
                  @if (showTitle) {
                    <p-heading [size]="'large'">Fargate Services</p-heading>
                  }
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    @for (service of dashboardResult.dev?.services; track service) {
                      <div class="col-4 mb-3">
                        <mat-card class="elevate-on-hover elevate-on-hover--8 {{service.supportsOpenApiDocs ? 'clickable' : ''}}"
                                  [appearance]="'outlined'"
                                  (click)="service.supportsOpenApiDocs ? openApiDocumentation(service.apiDocumentationUrl) : $event.stopPropagation()">
                          <mat-card-content class="container">
                            <div class="row">
                              <div class="col-lg-8 col-sm-12">
                                <div class="heading heading-large">{{ service.serviceName }}</div>
                                <span class="heading-small">Version: {{ service.version }}</span>
                              </div>
                              <div class="col-lg col-sm-12 d-sm-flex justify-content-sm-center">
                                <app-service-status [service]="service"></app-service-status>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-2">
                <div class="col-12">
                  @if (showTitle) {
                    <p-heading [size]="'large'">Lambdas</p-heading>
                  }
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    @for (lambda of dashboardResult.dev?.lambdas; track lambda) {
                      <div class="col-4 mb-3">
                        <mat-card class="elevate-on-hover elevate-on-hover--8" [appearance]="'outlined'">
                          <mat-card-content class="container">
                            <div class="row">
                              <div class="col-lg-12 col-sm-12">
                                <div class="heading heading-large">{{ lambda.name }}</div>
                                @if (lambda.version) {
                                  <span class="heading-small">Version: {{ lambda.version }}</span>
                                }
                                <div class="heading-small">State: {{ lambda.state }}</div>
                                @if (lambda.stateReason) {
                                  <div class="heading-small">State Reason: {{ lambda.stateReason }}</div>
                                }
                                @if (lambda.stateReasonCode) {
                                  <div class="heading-small">State Reason Code: {{ lambda.stateReasonCode }}</div>
                                }
                              </div>
                              <div class="col-lg col-sm-12 d-sm-flex justify-content-sm-center">
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    }
                  </div>
                </div>
              </div>


            </div>
          </div>
        </mat-tab>
        <mat-tab [label]="'Preview'">
          <div class="row mb-3">
            <div class="col">
              <div class="row mt-4 mb-2">
                <div class="col-12">
                  <p-heading [size]="'large'">Fargate Services</p-heading>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    @for (service of dashboardResult.preview?.services; track service) {
                      <div class="col-4 mb-3">
                        <mat-card class="elevate-on-hover elevate-on-hover--8 {{service.supportsOpenApiDocs ? 'clickable' : ''}}"
                                  [appearance]="'outlined'"
                                  (click)="service.supportsOpenApiDocs ? openApiDocumentation(service.apiDocumentationUrl) : $event.stopPropagation()">
                          <mat-card-content class="container">
                            <div class="row">
                              <div class="col-lg-8 col-sm-12">
                                <div class="heading heading-large">{{ service.serviceName }}</div>
                                <span class="heading-small">Version: {{ service.version }}</span>
                              </div>
                              <div class="col-lg col-sm-12 d-sm-flex justify-content-sm-center">
                                <app-service-status [service]="service"></app-service-status>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-2">
                <div class="col-12">
                  <p-heading [size]="'large'">Lambdas</p-heading>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    @for (lambda of dashboardResult.preview?.lambdas; track lambda) {
                      <div class="col-4 mb-3">
                        <mat-card class="elevate-on-hover elevate-on-hover--8" [appearance]="'outlined'">
                          <mat-card-content class="container">
                            <div class="row">
                              <div class="col-lg-12 col-sm-12">
                                <div class="heading heading-large">{{ lambda.name }}</div>
                                @if (lambda.version) {
                                  <span class="heading-small">Version: {{ lambda.version }}</span>
                                }
                                <div class="heading-small">State: {{ lambda.state }}</div>
                                @if (lambda.stateReason) {
                                  <div class="heading-small">State Reason: {{ lambda.stateReason }}</div>
                                }
                                @if (lambda.stateReasonCode) {
                                  <div class="heading-small">State Reason Code: {{ lambda.stateReasonCode }}</div>
                                }
                              </div>
                              <div class="col-lg col-sm-12 d-sm-flex justify-content-sm-center">
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    }
                  </div>
                </div>
              </div>


            </div>
          </div>
        </mat-tab>
        <mat-tab [label]="'Prod'">
          <div class="row mb-3">
            <div class="col">
              <div class="row mt-4 mb-2">
                <div class="col-12">
                  <p-heading [size]="'large'">Fargate Services</p-heading>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    @for (service of dashboardResult.prod?.services; track service) {
                      <div class="col-4 mb-3">
                        <mat-card class="elevate-on-hover elevate-on-hover--8 {{service.supportsOpenApiDocs ? 'clickable' : ''}}"
                                  [appearance]="'outlined'"
                                  (click)="service.supportsOpenApiDocs ? openApiDocumentation(service.apiDocumentationUrl) : $event.stopPropagation()">
                          <mat-card-content class="container">
                            <div class="row">
                              <div class="col-lg-8 col-sm-12">
                                <div class="heading heading-large">{{ service.serviceName }}</div>
                                <span class="heading-small">Version: {{ service.version }}</span>
                              </div>
                              <div class="col-lg col-sm-12 d-sm-flex justify-content-sm-center">
                                <app-service-status [service]="service"></app-service-status>
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-2">
                <div class="col-12">
                  <p-heading [size]="'large'">Lambdas</p-heading>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    @for (lambda of dashboardResult.prod?.lambdas; track lambda) {
                      <div class="col-4 mb-3">
                        <mat-card class="elevate-on-hover elevate-on-hover--8" [appearance]="'outlined'">
                          <mat-card-content class="container">
                            <div class="row">
                              <div class="col-lg-12 col-sm-12">
                                <div class="heading heading-large">{{ lambda.name }}</div>
                                @if (lambda.version) {
                                  <span class="heading-small">Version: {{ lambda.version }}</span>
                                }
                                <div class="heading-small">State: {{ lambda.state }}</div>
                                @if (lambda.stateReason) {
                                  <div class="heading-small">State Reason: {{ lambda.stateReason }}</div>
                                }
                                @if (lambda.stateReasonCode) {
                                  <div class="heading-small">State Reason Code: {{ lambda.stateReasonCode }}</div>
                                }
                              </div>
                              <div class="col-lg col-sm-12 d-sm-flex justify-content-sm-center">
                              </div>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
}
