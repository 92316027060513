<span class="row status-box">
  <div class="col text-medium">
      <span class="status-display d-flex justify-content-end">
        @switch (service?.status) {
          @case ('OK') {
            <mat-icon class="ok" aria-hidden="false" aria-label="Example home icon"
                      [matTooltip]="'Service OK'"
                      fontIcon="check_circle">
            </mat-icon>
          }
          @case ('PROBLEM') {
            <mat-icon class="problem" aria-hidden="false" aria-label="Example home icon"
                      [matTooltip]="'Service has a problem'"
                      fontIcon="warning"></mat-icon>
          }
          @case ('SYSTEM_PARTIALLY_DOWN') {
            <mat-icon class="partially_down" aria-hidden="false"
                      [matTooltip]="'Service partially down'"
                      aria-label="Example home icon" fontIcon="error"></mat-icon>
          }
          @case ('SYSTEM_DOWN') {
            <mat-icon class="down" aria-hidden="false" aria-label="Example home icon"
                      [matTooltip]="'Service is down'"
                      fontIcon="error"></mat-icon>
          }
        }
      </span>
    </div>
</span>
