export const environment = {
  production: false,
  apiBaseUrl: "https://service-manager-backend.dev.services-pfs.vwfs.io",
  issuerUri: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_cdgQ49jd2",
  redirectUri: "https://service-manager.dev.services-pfs.vwfs.io/dashboard",
  clientId: '2qkvqfppkmgs1lc18stptubm5t',
  responseType: 'code',
  scope: 'email openid phone service-manager/service-manager.read',
  identityProvider: "Entra-Id-IdentityProvider",
};
