<div class="row mb-3">
  <div class="col">
    <p-heading [size]="'large'">Fargate/ECS services</p-heading>
  </div>
</div>
<div class="row mb-5">
  @for (service of services$ | async; track service) {
    <div class="col-3 mb-3">
      <mat-card class="elevate-on-hover elevate-on-hover--8 {{service.supportsOpenApiDocs ? 'clickable' : ''}}" [appearance]="'outlined'"
                (click)="service.supportsOpenApiDocs ? openApiDocumentation(service.apiDocumentationUrl) : $event.stopPropagation()">
        <mat-card-content class="container">
          <div class="row">
            <div class="col-lg-8 col-sm-12">
              <div class="heading heading-large">{{ service.serviceName }}</div>
              <span class="heading-small">Version: {{ service.version }}</span>
            </div>
            <div class="col-lg col-sm-12 d-sm-flex justify-content-sm-center">
              <app-service-status [service]="service"></app-service-status>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  }
</div>

<div class="row mb-3">
  <div class="col">
    <p-heading [size]="'large'">Lambdas</p-heading>
  </div>
</div>
<div class="row">
  @for (lambda of lambdas$ | async; track lambda) {
    <div class="col-3 mb-3">
      <mat-card class="elevate-on-hover elevate-on-hover--8" [appearance]="'outlined'">
        <mat-card-content class="container">
          <div class="row">
            <div class="col-lg-12 col-sm-12">
              <div class="heading heading-large">{{ lambda.name }}</div>
              @if(lambda.version) {
                <span class="heading-small">Version: {{ lambda.version }}</span>
              }
              <div class="heading-small">State: {{ lambda.state }}</div>
              @if (lambda.stateReason) {
                <div class="heading-small">State Reason: {{ lambda.stateReason }}</div>
              }
              @if (lambda.stateReasonCode) {
                <div class="heading-small">State Reason Code: {{ lambda.stateReasonCode }}</div>
              }
            </div>
            <div class="col-lg col-sm-12 d-sm-flex justify-content-sm-center">
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  }
</div>
