import {Component, OnInit} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {StatusService} from "./status.service";

import {MatIconModule} from "@angular/material/icon";
import {ServiceStatusComponent} from "../service-status/service-status.component";
import {Service} from "../model/service";
import {Observable} from "rxjs";
import {AsyncPipe} from "@angular/common";
import { Lambda } from "../model/lambda";
import { PorscheDesignSystemModule } from "@porsche-design-system/components-angular";

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    MatButton,
    MatCardModule,
    MatIconModule,
    MatIconModule,
    ServiceStatusComponent,
    AsyncPipe,
    PorscheDesignSystemModule
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent implements OnInit {

  services$: Observable<Service[]> | undefined;
  lambdas$: Observable<Lambda[]> | undefined;

  constructor(private healthService: StatusService) {
  }

  ngOnInit(): void {
    this.services$ = this.healthService.getServiceHealth();
    this.lambdas$ = this.healthService.getLambdas();
  }

  public openApiDocumentation(apiDocumentationUrl: string): void {
    window.open(apiDocumentationUrl, '_blank');
  }
}
