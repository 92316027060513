import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimations} from "@angular/platform-browser/animations";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { authInterceptor, LogLevel, provideAuth, withAppInitializerAuthCheck } from "angular-auth-oidc-client";
import {environment} from "../environments/environment";


export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideAnimations(), provideHttpClient(withInterceptors([authInterceptor()])), provideAuth({
    config: {
      authority: environment.issuerUri,
      redirectUrl: environment.redirectUri,
      clientId: environment.clientId,
      scope: environment.scope,
      responseType: 'code',
      logLevel: LogLevel.Debug,
      customParamsAuthRequest: {identity_provider: environment.identityProvider},
      secureRoutes: [`${environment.apiBaseUrl}/dashboard`],
    },
  }, withAppInitializerAuthCheck())]
};
