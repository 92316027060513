<header class="border-width-base mb-5 pt-3 pb-3">
  <div class="row">
    <div class="col-10 pt-2 pb-2 d-flex">
      <p-wordmark class="ml-3"></p-wordmark>
    </div>
  </div>
</header>
<section class="container-fluid">
  <router-outlet/>
</section>

