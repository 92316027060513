import {Component} from '@angular/core';
import {ServicesComponent} from "./services/services.component";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    ServicesComponent
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent  {
}
