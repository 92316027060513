import { load } from '@porsche-design-system/components-js';
const _c0 = ["*"];
export { componentsReady } from '@porsche-design-system/components-js';
import * as i0 from '@angular/core';
import { InjectionToken, Component, ChangeDetectionStrategy, inject, EventEmitter, NgModule, Optional, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
const THEME_TOKEN = new InjectionToken('pdsTheme');
class BaseComponent {
  el;
  constructor(cdr, elementRef) {
    cdr.detach();
    this.el = elementRef.nativeElement;
  }
  ngOnChanges(props) {
    for (const prop in props) {
      this.el[prop] = props[prop].currentValue;
    }
  }
  static ɵfac = function BaseComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BaseComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: BaseComponent,
    selectors: [["ng-component"]],
    features: [i0.ɵɵNgOnChangesFeature],
    decls: 0,
    vars: 0,
    template: function BaseComponent_Template(rf, ctx) {},
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseComponent, [{
    type: Component,
    args: [{
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }], null);
})();
class BaseComponentWithTheme extends BaseComponent {
  theme;
  themeSubscription = inject(THEME_TOKEN).subscribe(theme => {
    this.el.theme = this.theme || theme;
  });
  ngOnDestroy() {
    // need to manually unsubscribe or otherwise subscription is still active even after unmount
    // https://rafaelneto.dev/en/blog/unsubscribing-behaviorsubject-observable-angular/
    this.themeSubscription.unsubscribe();
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵBaseComponentWithTheme_BaseFactory;
    return function BaseComponentWithTheme_Factory(__ngFactoryType__) {
      return (ɵBaseComponentWithTheme_BaseFactory || (ɵBaseComponentWithTheme_BaseFactory = i0.ɵɵgetInheritedFactory(BaseComponentWithTheme)))(__ngFactoryType__ || BaseComponentWithTheme);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: BaseComponentWithTheme,
    selectors: [["ng-component"]],
    features: [i0.ɵɵInheritDefinitionFeature],
    decls: 0,
    vars: 0,
    template: function BaseComponentWithTheme_Template(rf, ctx) {},
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseComponentWithTheme, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], null, null);
})();
class PAccordion extends BaseComponentWithTheme {
  compact;
  heading;
  headingTag;
  open;
  size;
  sticky;
  /** @deprecated */
  tag;
  theme;
  /** @deprecated */
  accordionChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPAccordion_BaseFactory;
    return function PAccordion_Factory(__ngFactoryType__) {
      return (ɵPAccordion_BaseFactory || (ɵPAccordion_BaseFactory = i0.ɵɵgetInheritedFactory(PAccordion)))(__ngFactoryType__ || PAccordion);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PAccordion,
    selectors: [["p-accordion"], ["", "p-accordion", ""]],
    inputs: {
      compact: "compact",
      heading: "heading",
      headingTag: "headingTag",
      open: "open",
      size: "size",
      sticky: "sticky",
      tag: "tag",
      theme: "theme"
    },
    outputs: {
      accordionChange: "accordionChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PAccordion_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PAccordion, [{
    type: Component,
    args: [{
      selector: 'p-accordion,[p-accordion]',
      template: '<ng-content />',
      inputs: ['compact', 'heading', 'headingTag', 'open', 'size', 'sticky', 'tag', 'theme'],
      outputs: ['accordionChange', 'update']
    }]
  }], null, null);
})();
class PBanner extends BaseComponentWithTheme {
  description;
  dismissButton;
  heading;
  headingTag;
  open;
  /** @deprecated */
  persistent;
  state;
  theme;
  /** @deprecated */
  width;
  dismiss = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPBanner_BaseFactory;
    return function PBanner_Factory(__ngFactoryType__) {
      return (ɵPBanner_BaseFactory || (ɵPBanner_BaseFactory = i0.ɵɵgetInheritedFactory(PBanner)))(__ngFactoryType__ || PBanner);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PBanner,
    selectors: [["p-banner"], ["", "p-banner", ""]],
    inputs: {
      description: "description",
      dismissButton: "dismissButton",
      heading: "heading",
      headingTag: "headingTag",
      open: "open",
      persistent: "persistent",
      state: "state",
      theme: "theme",
      width: "width"
    },
    outputs: {
      dismiss: "dismiss"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PBanner_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PBanner, [{
    type: Component,
    args: [{
      selector: 'p-banner,[p-banner]',
      template: '<ng-content />',
      inputs: ['description', 'dismissButton', 'heading', 'headingTag', 'open', 'persistent', 'state', 'theme', 'width'],
      outputs: ['dismiss']
    }]
  }], null, null);
})();
class PButton extends BaseComponentWithTheme {
  aria;
  compact;
  disabled;
  hideLabel;
  icon;
  iconSource;
  loading;
  name;
  theme;
  type;
  value;
  variant;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPButton_BaseFactory;
    return function PButton_Factory(__ngFactoryType__) {
      return (ɵPButton_BaseFactory || (ɵPButton_BaseFactory = i0.ɵɵgetInheritedFactory(PButton)))(__ngFactoryType__ || PButton);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PButton,
    selectors: [["p-button"], ["", "p-button", ""]],
    inputs: {
      aria: "aria",
      compact: "compact",
      disabled: "disabled",
      hideLabel: "hideLabel",
      icon: "icon",
      iconSource: "iconSource",
      loading: "loading",
      name: "name",
      theme: "theme",
      type: "type",
      value: "value",
      variant: "variant"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PButton_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PButton, [{
    type: Component,
    args: [{
      selector: 'p-button,[p-button]',
      template: '<ng-content />',
      inputs: ['aria', 'compact', 'disabled', 'hideLabel', 'icon', 'iconSource', 'loading', 'name', 'theme', 'type', 'value', 'variant']
    }]
  }], null, null);
})();
class PButtonGroup extends BaseComponent {
  direction;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPButtonGroup_BaseFactory;
    return function PButtonGroup_Factory(__ngFactoryType__) {
      return (ɵPButtonGroup_BaseFactory || (ɵPButtonGroup_BaseFactory = i0.ɵɵgetInheritedFactory(PButtonGroup)))(__ngFactoryType__ || PButtonGroup);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PButtonGroup,
    selectors: [["p-button-group"], ["", "p-button-group", ""]],
    inputs: {
      direction: "direction"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PButtonGroup_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PButtonGroup, [{
    type: Component,
    args: [{
      selector: 'p-button-group,[p-button-group]',
      template: '<ng-content />',
      inputs: ['direction']
    }]
  }], null, null);
})();
class PButtonPure extends BaseComponentWithTheme {
  active;
  alignLabel;
  aria;
  disabled;
  hideLabel;
  icon;
  iconSource;
  loading;
  name;
  size;
  stretch;
  theme;
  type;
  underline;
  value;
  /** @deprecated */
  weight;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPButtonPure_BaseFactory;
    return function PButtonPure_Factory(__ngFactoryType__) {
      return (ɵPButtonPure_BaseFactory || (ɵPButtonPure_BaseFactory = i0.ɵɵgetInheritedFactory(PButtonPure)))(__ngFactoryType__ || PButtonPure);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PButtonPure,
    selectors: [["p-button-pure"], ["", "p-button-pure", ""]],
    inputs: {
      active: "active",
      alignLabel: "alignLabel",
      aria: "aria",
      disabled: "disabled",
      hideLabel: "hideLabel",
      icon: "icon",
      iconSource: "iconSource",
      loading: "loading",
      name: "name",
      size: "size",
      stretch: "stretch",
      theme: "theme",
      type: "type",
      underline: "underline",
      value: "value",
      weight: "weight"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PButtonPure_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PButtonPure, [{
    type: Component,
    args: [{
      selector: 'p-button-pure,[p-button-pure]',
      template: '<ng-content />',
      inputs: ['active', 'alignLabel', 'aria', 'disabled', 'hideLabel', 'icon', 'iconSource', 'loading', 'name', 'size', 'stretch', 'theme', 'type', 'underline', 'value', 'weight']
    }]
  }], null, null);
})();
class PButtonTile extends BaseComponent {
  align;
  aria;
  aspectRatio;
  background;
  compact;
  description;
  disabled;
  gradient;
  icon;
  iconSource;
  label;
  loading;
  size;
  type;
  weight;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPButtonTile_BaseFactory;
    return function PButtonTile_Factory(__ngFactoryType__) {
      return (ɵPButtonTile_BaseFactory || (ɵPButtonTile_BaseFactory = i0.ɵɵgetInheritedFactory(PButtonTile)))(__ngFactoryType__ || PButtonTile);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PButtonTile,
    selectors: [["p-button-tile"], ["", "p-button-tile", ""]],
    inputs: {
      align: "align",
      aria: "aria",
      aspectRatio: "aspectRatio",
      background: "background",
      compact: "compact",
      description: "description",
      disabled: "disabled",
      gradient: "gradient",
      icon: "icon",
      iconSource: "iconSource",
      label: "label",
      loading: "loading",
      size: "size",
      type: "type",
      weight: "weight"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PButtonTile_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PButtonTile, [{
    type: Component,
    args: [{
      selector: 'p-button-tile,[p-button-tile]',
      template: '<ng-content />',
      inputs: ['align', 'aria', 'aspectRatio', 'background', 'compact', 'description', 'disabled', 'gradient', 'icon', 'iconSource', 'label', 'loading', 'size', 'type', 'weight']
    }]
  }], null, null);
})();
class PCanvas extends BaseComponentWithTheme {
  sidebarEndOpen;
  sidebarStartOpen;
  theme;
  sidebarEndDismiss = new EventEmitter();
  sidebarStartUpdate = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPCanvas_BaseFactory;
    return function PCanvas_Factory(__ngFactoryType__) {
      return (ɵPCanvas_BaseFactory || (ɵPCanvas_BaseFactory = i0.ɵɵgetInheritedFactory(PCanvas)))(__ngFactoryType__ || PCanvas);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PCanvas,
    selectors: [["p-canvas"], ["", "p-canvas", ""]],
    inputs: {
      sidebarEndOpen: "sidebarEndOpen",
      sidebarStartOpen: "sidebarStartOpen",
      theme: "theme"
    },
    outputs: {
      sidebarEndDismiss: "sidebarEndDismiss",
      sidebarStartUpdate: "sidebarStartUpdate"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PCanvas_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PCanvas, [{
    type: Component,
    args: [{
      selector: 'p-canvas,[p-canvas]',
      template: '<ng-content />',
      inputs: ['sidebarEndOpen', 'sidebarStartOpen', 'theme'],
      outputs: ['sidebarEndDismiss', 'sidebarStartUpdate']
    }]
  }], null, null);
})();
class PCarousel extends BaseComponentWithTheme {
  activeSlideIndex;
  alignHeader;
  aria;
  description;
  /** @deprecated */
  disablePagination;
  focusOnCenterSlide;
  gradientColor;
  heading;
  headingSize;
  intl;
  pagination;
  rewind;
  skipLinkTarget;
  slidesPerPage;
  theme;
  trimSpace;
  width;
  /** @deprecated */
  wrapContent;
  /** @deprecated */
  carouselChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPCarousel_BaseFactory;
    return function PCarousel_Factory(__ngFactoryType__) {
      return (ɵPCarousel_BaseFactory || (ɵPCarousel_BaseFactory = i0.ɵɵgetInheritedFactory(PCarousel)))(__ngFactoryType__ || PCarousel);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PCarousel,
    selectors: [["p-carousel"], ["", "p-carousel", ""]],
    inputs: {
      activeSlideIndex: "activeSlideIndex",
      alignHeader: "alignHeader",
      aria: "aria",
      description: "description",
      disablePagination: "disablePagination",
      focusOnCenterSlide: "focusOnCenterSlide",
      gradientColor: "gradientColor",
      heading: "heading",
      headingSize: "headingSize",
      intl: "intl",
      pagination: "pagination",
      rewind: "rewind",
      skipLinkTarget: "skipLinkTarget",
      slidesPerPage: "slidesPerPage",
      theme: "theme",
      trimSpace: "trimSpace",
      width: "width",
      wrapContent: "wrapContent"
    },
    outputs: {
      carouselChange: "carouselChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PCarousel_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PCarousel, [{
    type: Component,
    args: [{
      selector: 'p-carousel,[p-carousel]',
      template: '<ng-content />',
      inputs: ['activeSlideIndex', 'alignHeader', 'aria', 'description', 'disablePagination', 'focusOnCenterSlide', 'gradientColor', 'heading', 'headingSize', 'intl', 'pagination', 'rewind', 'skipLinkTarget', 'slidesPerPage', 'theme', 'trimSpace', 'width', 'wrapContent'],
      outputs: ['carouselChange', 'update']
    }]
  }], null, null);
})();
class PCheckbox extends BaseComponentWithTheme {
  checked;
  compact;
  disabled;
  form;
  hideLabel;
  indeterminate;
  label;
  loading;
  message;
  name;
  required;
  state;
  theme;
  value;
  blur = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPCheckbox_BaseFactory;
    return function PCheckbox_Factory(__ngFactoryType__) {
      return (ɵPCheckbox_BaseFactory || (ɵPCheckbox_BaseFactory = i0.ɵɵgetInheritedFactory(PCheckbox)))(__ngFactoryType__ || PCheckbox);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PCheckbox,
    selectors: [["p-checkbox"], ["", "p-checkbox", ""]],
    inputs: {
      checked: "checked",
      compact: "compact",
      disabled: "disabled",
      form: "form",
      hideLabel: "hideLabel",
      indeterminate: "indeterminate",
      label: "label",
      loading: "loading",
      message: "message",
      name: "name",
      required: "required",
      state: "state",
      theme: "theme",
      value: "value"
    },
    outputs: {
      blur: "blur",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PCheckbox_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PCheckbox, [{
    type: Component,
    args: [{
      selector: 'p-checkbox,[p-checkbox]',
      template: '<ng-content />',
      inputs: ['checked', 'compact', 'disabled', 'form', 'hideLabel', 'indeterminate', 'label', 'loading', 'message', 'name', 'required', 'state', 'theme', 'value'],
      outputs: ['blur', 'update']
    }]
  }], null, null);
})();
class PCheckboxWrapper extends BaseComponentWithTheme {
  hideLabel;
  label;
  loading;
  message;
  state;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPCheckboxWrapper_BaseFactory;
    return function PCheckboxWrapper_Factory(__ngFactoryType__) {
      return (ɵPCheckboxWrapper_BaseFactory || (ɵPCheckboxWrapper_BaseFactory = i0.ɵɵgetInheritedFactory(PCheckboxWrapper)))(__ngFactoryType__ || PCheckboxWrapper);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PCheckboxWrapper,
    selectors: [["p-checkbox-wrapper"], ["", "p-checkbox-wrapper", ""]],
    inputs: {
      hideLabel: "hideLabel",
      label: "label",
      loading: "loading",
      message: "message",
      state: "state",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PCheckboxWrapper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PCheckboxWrapper, [{
    type: Component,
    args: [{
      selector: 'p-checkbox-wrapper,[p-checkbox-wrapper]',
      template: '<ng-content />',
      inputs: ['hideLabel', 'label', 'loading', 'message', 'state', 'theme']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Use native CSS Grid instead. */
class PContentWrapper extends BaseComponentWithTheme {
  /** @deprecated */
  backgroundColor;
  /** @deprecated */
  theme;
  width;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPContentWrapper_BaseFactory;
    return function PContentWrapper_Factory(__ngFactoryType__) {
      return (ɵPContentWrapper_BaseFactory || (ɵPContentWrapper_BaseFactory = i0.ɵɵgetInheritedFactory(PContentWrapper)))(__ngFactoryType__ || PContentWrapper);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PContentWrapper,
    selectors: [["p-content-wrapper"], ["", "p-content-wrapper", ""]],
    inputs: {
      backgroundColor: "backgroundColor",
      theme: "theme",
      width: "width"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PContentWrapper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PContentWrapper, [{
    type: Component,
    args: [{
      selector: 'p-content-wrapper,[p-content-wrapper]',
      template: '<ng-content />',
      inputs: ['backgroundColor', 'theme', 'width']
    }]
  }], null, null);
})();
class PCrest extends BaseComponent {
  aria;
  href;
  target;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPCrest_BaseFactory;
    return function PCrest_Factory(__ngFactoryType__) {
      return (ɵPCrest_BaseFactory || (ɵPCrest_BaseFactory = i0.ɵɵgetInheritedFactory(PCrest)))(__ngFactoryType__ || PCrest);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PCrest,
    selectors: [["p-crest"], ["", "p-crest", ""]],
    inputs: {
      aria: "aria",
      href: "href",
      target: "target"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PCrest_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PCrest, [{
    type: Component,
    args: [{
      selector: 'p-crest,[p-crest]',
      template: '<ng-content />',
      inputs: ['aria', 'href', 'target']
    }]
  }], null, null);
})();
class PDisplay extends BaseComponentWithTheme {
  align;
  color;
  ellipsis;
  size;
  tag;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPDisplay_BaseFactory;
    return function PDisplay_Factory(__ngFactoryType__) {
      return (ɵPDisplay_BaseFactory || (ɵPDisplay_BaseFactory = i0.ɵɵgetInheritedFactory(PDisplay)))(__ngFactoryType__ || PDisplay);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PDisplay,
    selectors: [["p-display"], ["", "p-display", ""]],
    inputs: {
      align: "align",
      color: "color",
      ellipsis: "ellipsis",
      size: "size",
      tag: "tag",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PDisplay_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDisplay, [{
    type: Component,
    args: [{
      selector: 'p-display,[p-display]',
      template: '<ng-content />',
      inputs: ['align', 'color', 'ellipsis', 'size', 'tag', 'theme']
    }]
  }], null, null);
})();
class PDivider extends BaseComponentWithTheme {
  color;
  direction;
  /** @deprecated */
  orientation;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPDivider_BaseFactory;
    return function PDivider_Factory(__ngFactoryType__) {
      return (ɵPDivider_BaseFactory || (ɵPDivider_BaseFactory = i0.ɵɵgetInheritedFactory(PDivider)))(__ngFactoryType__ || PDivider);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PDivider,
    selectors: [["p-divider"], ["", "p-divider", ""]],
    inputs: {
      color: "color",
      direction: "direction",
      orientation: "orientation",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PDivider_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PDivider, [{
    type: Component,
    args: [{
      selector: 'p-divider,[p-divider]',
      template: '<ng-content />',
      inputs: ['color', 'direction', 'orientation', 'theme']
    }]
  }], null, null);
})();
class PFieldset extends BaseComponentWithTheme {
  label;
  labelSize;
  message;
  required;
  state;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPFieldset_BaseFactory;
    return function PFieldset_Factory(__ngFactoryType__) {
      return (ɵPFieldset_BaseFactory || (ɵPFieldset_BaseFactory = i0.ɵɵgetInheritedFactory(PFieldset)))(__ngFactoryType__ || PFieldset);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PFieldset,
    selectors: [["p-fieldset"], ["", "p-fieldset", ""]],
    inputs: {
      label: "label",
      labelSize: "labelSize",
      message: "message",
      required: "required",
      state: "state",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PFieldset_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PFieldset, [{
    type: Component,
    args: [{
      selector: 'p-fieldset,[p-fieldset]',
      template: '<ng-content />',
      inputs: ['label', 'labelSize', 'message', 'required', 'state', 'theme']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Please use "p-fieldset" instead. */
class PFieldsetWrapper extends BaseComponentWithTheme {
  label;
  labelSize;
  message;
  required;
  state;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPFieldsetWrapper_BaseFactory;
    return function PFieldsetWrapper_Factory(__ngFactoryType__) {
      return (ɵPFieldsetWrapper_BaseFactory || (ɵPFieldsetWrapper_BaseFactory = i0.ɵɵgetInheritedFactory(PFieldsetWrapper)))(__ngFactoryType__ || PFieldsetWrapper);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PFieldsetWrapper,
    selectors: [["p-fieldset-wrapper"], ["", "p-fieldset-wrapper", ""]],
    inputs: {
      label: "label",
      labelSize: "labelSize",
      message: "message",
      required: "required",
      state: "state",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PFieldsetWrapper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PFieldsetWrapper, [{
    type: Component,
    args: [{
      selector: 'p-fieldset-wrapper,[p-fieldset-wrapper]',
      template: '<ng-content />',
      inputs: ['label', 'labelSize', 'message', 'required', 'state', 'theme']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Use native CSS Flex instead. */
class PFlex extends BaseComponent {
  alignContent;
  alignItems;
  direction;
  inline;
  justifyContent;
  wrap;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPFlex_BaseFactory;
    return function PFlex_Factory(__ngFactoryType__) {
      return (ɵPFlex_BaseFactory || (ɵPFlex_BaseFactory = i0.ɵɵgetInheritedFactory(PFlex)))(__ngFactoryType__ || PFlex);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PFlex,
    selectors: [["p-flex"], ["", "p-flex", ""]],
    inputs: {
      alignContent: "alignContent",
      alignItems: "alignItems",
      direction: "direction",
      inline: "inline",
      justifyContent: "justifyContent",
      wrap: "wrap"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PFlex_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PFlex, [{
    type: Component,
    args: [{
      selector: 'p-flex,[p-flex]',
      template: '<ng-content />',
      inputs: ['alignContent', 'alignItems', 'direction', 'inline', 'justifyContent', 'wrap']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Use native CSS Flex instead. */
class PFlexItem extends BaseComponent {
  alignSelf;
  flex;
  grow;
  offset;
  shrink;
  width;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPFlexItem_BaseFactory;
    return function PFlexItem_Factory(__ngFactoryType__) {
      return (ɵPFlexItem_BaseFactory || (ɵPFlexItem_BaseFactory = i0.ɵɵgetInheritedFactory(PFlexItem)))(__ngFactoryType__ || PFlexItem);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PFlexItem,
    selectors: [["p-flex-item"], ["", "p-flex-item", ""]],
    inputs: {
      alignSelf: "alignSelf",
      flex: "flex",
      grow: "grow",
      offset: "offset",
      shrink: "shrink",
      width: "width"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PFlexItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PFlexItem, [{
    type: Component,
    args: [{
      selector: 'p-flex-item,[p-flex-item]',
      template: '<ng-content />',
      inputs: ['alignSelf', 'flex', 'grow', 'offset', 'shrink', 'width']
    }]
  }], null, null);
})();
class PFlyout extends BaseComponentWithTheme {
  aria;
  disableBackdropClick;
  open;
  position;
  theme;
  dismiss = new EventEmitter();
  motionHiddenEnd = new EventEmitter();
  motionVisibleEnd = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPFlyout_BaseFactory;
    return function PFlyout_Factory(__ngFactoryType__) {
      return (ɵPFlyout_BaseFactory || (ɵPFlyout_BaseFactory = i0.ɵɵgetInheritedFactory(PFlyout)))(__ngFactoryType__ || PFlyout);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PFlyout,
    selectors: [["p-flyout"], ["", "p-flyout", ""]],
    inputs: {
      aria: "aria",
      disableBackdropClick: "disableBackdropClick",
      open: "open",
      position: "position",
      theme: "theme"
    },
    outputs: {
      dismiss: "dismiss",
      motionHiddenEnd: "motionHiddenEnd",
      motionVisibleEnd: "motionVisibleEnd"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PFlyout_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PFlyout, [{
    type: Component,
    args: [{
      selector: 'p-flyout,[p-flyout]',
      template: '<ng-content />',
      inputs: ['aria', 'disableBackdropClick', 'open', 'position', 'theme'],
      outputs: ['dismiss', 'motionHiddenEnd', 'motionVisibleEnd']
    }]
  }], null, null);
})();
class PFlyoutMultilevel extends BaseComponentWithTheme {
  activeIdentifier;
  aria;
  open;
  theme;
  dismiss = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPFlyoutMultilevel_BaseFactory;
    return function PFlyoutMultilevel_Factory(__ngFactoryType__) {
      return (ɵPFlyoutMultilevel_BaseFactory || (ɵPFlyoutMultilevel_BaseFactory = i0.ɵɵgetInheritedFactory(PFlyoutMultilevel)))(__ngFactoryType__ || PFlyoutMultilevel);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PFlyoutMultilevel,
    selectors: [["p-flyout-multilevel"], ["", "p-flyout-multilevel", ""]],
    inputs: {
      activeIdentifier: "activeIdentifier",
      aria: "aria",
      open: "open",
      theme: "theme"
    },
    outputs: {
      dismiss: "dismiss",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PFlyoutMultilevel_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PFlyoutMultilevel, [{
    type: Component,
    args: [{
      selector: 'p-flyout-multilevel,[p-flyout-multilevel]',
      template: '<ng-content />',
      inputs: ['activeIdentifier', 'aria', 'open', 'theme'],
      outputs: ['dismiss', 'update']
    }]
  }], null, null);
})();
class PFlyoutMultilevelItem extends BaseComponent {
  identifier;
  label;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPFlyoutMultilevelItem_BaseFactory;
    return function PFlyoutMultilevelItem_Factory(__ngFactoryType__) {
      return (ɵPFlyoutMultilevelItem_BaseFactory || (ɵPFlyoutMultilevelItem_BaseFactory = i0.ɵɵgetInheritedFactory(PFlyoutMultilevelItem)))(__ngFactoryType__ || PFlyoutMultilevelItem);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PFlyoutMultilevelItem,
    selectors: [["p-flyout-multilevel-item"], ["", "p-flyout-multilevel-item", ""]],
    inputs: {
      identifier: "identifier",
      label: "label"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PFlyoutMultilevelItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PFlyoutMultilevelItem, [{
    type: Component,
    args: [{
      selector: 'p-flyout-multilevel-item,[p-flyout-multilevel-item]',
      template: '<ng-content />',
      inputs: ['identifier', 'label']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Use native CSS Grid instead. */
class PGrid extends BaseComponent {
  direction;
  /** @deprecated */
  gutter;
  wrap;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPGrid_BaseFactory;
    return function PGrid_Factory(__ngFactoryType__) {
      return (ɵPGrid_BaseFactory || (ɵPGrid_BaseFactory = i0.ɵɵgetInheritedFactory(PGrid)))(__ngFactoryType__ || PGrid);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PGrid,
    selectors: [["p-grid"], ["", "p-grid", ""]],
    inputs: {
      direction: "direction",
      gutter: "gutter",
      wrap: "wrap"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PGrid_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PGrid, [{
    type: Component,
    args: [{
      selector: 'p-grid,[p-grid]',
      template: '<ng-content />',
      inputs: ['direction', 'gutter', 'wrap']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Use native CSS Grid instead. */
class PGridItem extends BaseComponent {
  offset;
  size;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPGridItem_BaseFactory;
    return function PGridItem_Factory(__ngFactoryType__) {
      return (ɵPGridItem_BaseFactory || (ɵPGridItem_BaseFactory = i0.ɵɵgetInheritedFactory(PGridItem)))(__ngFactoryType__ || PGridItem);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PGridItem,
    selectors: [["p-grid-item"], ["", "p-grid-item", ""]],
    inputs: {
      offset: "offset",
      size: "size"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PGridItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PGridItem, [{
    type: Component,
    args: [{
      selector: 'p-grid-item,[p-grid-item]',
      template: '<ng-content />',
      inputs: ['offset', 'size']
    }]
  }], null, null);
})();
class PHeading extends BaseComponentWithTheme {
  align;
  color;
  ellipsis;
  size;
  tag;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPHeading_BaseFactory;
    return function PHeading_Factory(__ngFactoryType__) {
      return (ɵPHeading_BaseFactory || (ɵPHeading_BaseFactory = i0.ɵɵgetInheritedFactory(PHeading)))(__ngFactoryType__ || PHeading);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PHeading,
    selectors: [["p-heading"], ["", "p-heading", ""]],
    inputs: {
      align: "align",
      color: "color",
      ellipsis: "ellipsis",
      size: "size",
      tag: "tag",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PHeading_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PHeading, [{
    type: Component,
    args: [{
      selector: 'p-heading,[p-heading]',
      template: '<ng-content />',
      inputs: ['align', 'color', 'ellipsis', 'size', 'tag', 'theme']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Please use "p-heading" instead. */
class PHeadline extends BaseComponentWithTheme {
  align;
  color;
  ellipsis;
  tag;
  theme;
  variant;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPHeadline_BaseFactory;
    return function PHeadline_Factory(__ngFactoryType__) {
      return (ɵPHeadline_BaseFactory || (ɵPHeadline_BaseFactory = i0.ɵɵgetInheritedFactory(PHeadline)))(__ngFactoryType__ || PHeadline);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PHeadline,
    selectors: [["p-headline"], ["", "p-headline", ""]],
    inputs: {
      align: "align",
      color: "color",
      ellipsis: "ellipsis",
      tag: "tag",
      theme: "theme",
      variant: "variant"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PHeadline_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PHeadline, [{
    type: Component,
    args: [{
      selector: 'p-headline,[p-headline]',
      template: '<ng-content />',
      inputs: ['align', 'color', 'ellipsis', 'tag', 'theme', 'variant']
    }]
  }], null, null);
})();
class PIcon extends BaseComponentWithTheme {
  aria;
  color;
  /** @deprecated */
  lazy;
  name;
  size;
  source;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPIcon_BaseFactory;
    return function PIcon_Factory(__ngFactoryType__) {
      return (ɵPIcon_BaseFactory || (ɵPIcon_BaseFactory = i0.ɵɵgetInheritedFactory(PIcon)))(__ngFactoryType__ || PIcon);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PIcon,
    selectors: [["p-icon"], ["", "p-icon", ""]],
    inputs: {
      aria: "aria",
      color: "color",
      lazy: "lazy",
      name: "name",
      size: "size",
      source: "source",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PIcon_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PIcon, [{
    type: Component,
    args: [{
      selector: 'p-icon,[p-icon]',
      template: '<ng-content />',
      inputs: ['aria', 'color', 'lazy', 'name', 'size', 'source', 'theme']
    }]
  }], null, null);
})();
class PInlineNotification extends BaseComponentWithTheme {
  actionIcon;
  actionLabel;
  actionLoading;
  description;
  dismissButton;
  heading;
  headingTag;
  /** @deprecated */
  persistent;
  state;
  theme;
  action = new EventEmitter();
  dismiss = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPInlineNotification_BaseFactory;
    return function PInlineNotification_Factory(__ngFactoryType__) {
      return (ɵPInlineNotification_BaseFactory || (ɵPInlineNotification_BaseFactory = i0.ɵɵgetInheritedFactory(PInlineNotification)))(__ngFactoryType__ || PInlineNotification);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PInlineNotification,
    selectors: [["p-inline-notification"], ["", "p-inline-notification", ""]],
    inputs: {
      actionIcon: "actionIcon",
      actionLabel: "actionLabel",
      actionLoading: "actionLoading",
      description: "description",
      dismissButton: "dismissButton",
      heading: "heading",
      headingTag: "headingTag",
      persistent: "persistent",
      state: "state",
      theme: "theme"
    },
    outputs: {
      action: "action",
      dismiss: "dismiss"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PInlineNotification_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PInlineNotification, [{
    type: Component,
    args: [{
      selector: 'p-inline-notification,[p-inline-notification]',
      template: '<ng-content />',
      inputs: ['actionIcon', 'actionLabel', 'actionLoading', 'description', 'dismissButton', 'heading', 'headingTag', 'persistent', 'state', 'theme'],
      outputs: ['action', 'dismiss']
    }]
  }], null, null);
})();
class PLink extends BaseComponentWithTheme {
  aria;
  compact;
  download;
  hideLabel;
  href;
  icon;
  iconSource;
  rel;
  target;
  theme;
  variant;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPLink_BaseFactory;
    return function PLink_Factory(__ngFactoryType__) {
      return (ɵPLink_BaseFactory || (ɵPLink_BaseFactory = i0.ɵɵgetInheritedFactory(PLink)))(__ngFactoryType__ || PLink);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PLink,
    selectors: [["p-link"], ["", "p-link", ""]],
    inputs: {
      aria: "aria",
      compact: "compact",
      download: "download",
      hideLabel: "hideLabel",
      href: "href",
      icon: "icon",
      iconSource: "iconSource",
      rel: "rel",
      target: "target",
      theme: "theme",
      variant: "variant"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PLink_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PLink, [{
    type: Component,
    args: [{
      selector: 'p-link,[p-link]',
      template: '<ng-content />',
      inputs: ['aria', 'compact', 'download', 'hideLabel', 'href', 'icon', 'iconSource', 'rel', 'target', 'theme', 'variant']
    }]
  }], null, null);
})();
class PLinkPure extends BaseComponentWithTheme {
  active;
  alignLabel;
  aria;
  download;
  hideLabel;
  href;
  icon;
  iconSource;
  rel;
  size;
  stretch;
  target;
  theme;
  underline;
  /** @deprecated */
  weight;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPLinkPure_BaseFactory;
    return function PLinkPure_Factory(__ngFactoryType__) {
      return (ɵPLinkPure_BaseFactory || (ɵPLinkPure_BaseFactory = i0.ɵɵgetInheritedFactory(PLinkPure)))(__ngFactoryType__ || PLinkPure);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PLinkPure,
    selectors: [["p-link-pure"], ["", "p-link-pure", ""]],
    inputs: {
      active: "active",
      alignLabel: "alignLabel",
      aria: "aria",
      download: "download",
      hideLabel: "hideLabel",
      href: "href",
      icon: "icon",
      iconSource: "iconSource",
      rel: "rel",
      size: "size",
      stretch: "stretch",
      target: "target",
      theme: "theme",
      underline: "underline",
      weight: "weight"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PLinkPure_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PLinkPure, [{
    type: Component,
    args: [{
      selector: 'p-link-pure,[p-link-pure]',
      template: '<ng-content />',
      inputs: ['active', 'alignLabel', 'aria', 'download', 'hideLabel', 'href', 'icon', 'iconSource', 'rel', 'size', 'stretch', 'target', 'theme', 'underline', 'weight']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Use `p-link` with corresponding social icon instead. */
class PLinkSocial extends BaseComponentWithTheme {
  compact;
  hideLabel;
  href;
  icon;
  iconSource;
  rel;
  target;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPLinkSocial_BaseFactory;
    return function PLinkSocial_Factory(__ngFactoryType__) {
      return (ɵPLinkSocial_BaseFactory || (ɵPLinkSocial_BaseFactory = i0.ɵɵgetInheritedFactory(PLinkSocial)))(__ngFactoryType__ || PLinkSocial);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PLinkSocial,
    selectors: [["p-link-social"], ["", "p-link-social", ""]],
    inputs: {
      compact: "compact",
      hideLabel: "hideLabel",
      href: "href",
      icon: "icon",
      iconSource: "iconSource",
      rel: "rel",
      target: "target",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PLinkSocial_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PLinkSocial, [{
    type: Component,
    args: [{
      selector: 'p-link-social,[p-link-social]',
      template: '<ng-content />',
      inputs: ['compact', 'hideLabel', 'href', 'icon', 'iconSource', 'rel', 'target', 'theme']
    }]
  }], null, null);
})();
class PLinkTile extends BaseComponent {
  align;
  aria;
  aspectRatio;
  background;
  compact;
  description;
  download;
  gradient;
  href;
  label;
  rel;
  size;
  target;
  weight;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPLinkTile_BaseFactory;
    return function PLinkTile_Factory(__ngFactoryType__) {
      return (ɵPLinkTile_BaseFactory || (ɵPLinkTile_BaseFactory = i0.ɵɵgetInheritedFactory(PLinkTile)))(__ngFactoryType__ || PLinkTile);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PLinkTile,
    selectors: [["p-link-tile"], ["", "p-link-tile", ""]],
    inputs: {
      align: "align",
      aria: "aria",
      aspectRatio: "aspectRatio",
      background: "background",
      compact: "compact",
      description: "description",
      download: "download",
      gradient: "gradient",
      href: "href",
      label: "label",
      rel: "rel",
      size: "size",
      target: "target",
      weight: "weight"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PLinkTile_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PLinkTile, [{
    type: Component,
    args: [{
      selector: 'p-link-tile,[p-link-tile]',
      template: '<ng-content />',
      inputs: ['align', 'aria', 'aspectRatio', 'background', 'compact', 'description', 'download', 'gradient', 'href', 'label', 'rel', 'size', 'target', 'weight']
    }]
  }], null, null);
})();
class PLinkTileModelSignature extends BaseComponent {
  aspectRatio;
  description;
  heading;
  headingTag;
  linkDirection;
  model;
  weight;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPLinkTileModelSignature_BaseFactory;
    return function PLinkTileModelSignature_Factory(__ngFactoryType__) {
      return (ɵPLinkTileModelSignature_BaseFactory || (ɵPLinkTileModelSignature_BaseFactory = i0.ɵɵgetInheritedFactory(PLinkTileModelSignature)))(__ngFactoryType__ || PLinkTileModelSignature);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PLinkTileModelSignature,
    selectors: [["p-link-tile-model-signature"], ["", "p-link-tile-model-signature", ""]],
    inputs: {
      aspectRatio: "aspectRatio",
      description: "description",
      heading: "heading",
      headingTag: "headingTag",
      linkDirection: "linkDirection",
      model: "model",
      weight: "weight"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PLinkTileModelSignature_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PLinkTileModelSignature, [{
    type: Component,
    args: [{
      selector: 'p-link-tile-model-signature,[p-link-tile-model-signature]',
      template: '<ng-content />',
      inputs: ['aspectRatio', 'description', 'heading', 'headingTag', 'linkDirection', 'model', 'weight']
    }]
  }], null, null);
})();
class PLinkTileProduct extends BaseComponentWithTheme {
  aspectRatio;
  description;
  heading;
  href;
  likeButton;
  liked;
  price;
  priceOriginal;
  rel;
  target;
  theme;
  like = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPLinkTileProduct_BaseFactory;
    return function PLinkTileProduct_Factory(__ngFactoryType__) {
      return (ɵPLinkTileProduct_BaseFactory || (ɵPLinkTileProduct_BaseFactory = i0.ɵɵgetInheritedFactory(PLinkTileProduct)))(__ngFactoryType__ || PLinkTileProduct);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PLinkTileProduct,
    selectors: [["p-link-tile-product"], ["", "p-link-tile-product", ""]],
    inputs: {
      aspectRatio: "aspectRatio",
      description: "description",
      heading: "heading",
      href: "href",
      likeButton: "likeButton",
      liked: "liked",
      price: "price",
      priceOriginal: "priceOriginal",
      rel: "rel",
      target: "target",
      theme: "theme"
    },
    outputs: {
      like: "like"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PLinkTileProduct_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PLinkTileProduct, [{
    type: Component,
    args: [{
      selector: 'p-link-tile-product,[p-link-tile-product]',
      template: '<ng-content />',
      inputs: ['aspectRatio', 'description', 'heading', 'href', 'likeButton', 'liked', 'price', 'priceOriginal', 'rel', 'target', 'theme'],
      outputs: ['like']
    }]
  }], null, null);
})();

/** @deprecated since v3.0.0, will be removed with next major release. Please use "p-wordmark" instead. */
class PMarque extends BaseComponent {
  aria;
  href;
  size;
  target;
  trademark;
  variant;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPMarque_BaseFactory;
    return function PMarque_Factory(__ngFactoryType__) {
      return (ɵPMarque_BaseFactory || (ɵPMarque_BaseFactory = i0.ɵɵgetInheritedFactory(PMarque)))(__ngFactoryType__ || PMarque);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PMarque,
    selectors: [["p-marque"], ["", "p-marque", ""]],
    inputs: {
      aria: "aria",
      href: "href",
      size: "size",
      target: "target",
      trademark: "trademark",
      variant: "variant"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PMarque_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PMarque, [{
    type: Component,
    args: [{
      selector: 'p-marque,[p-marque]',
      template: '<ng-content />',
      inputs: ['aria', 'href', 'size', 'target', 'trademark', 'variant']
    }]
  }], null, null);
})();
class PModal extends BaseComponentWithTheme {
  aria;
  backdrop;
  disableBackdropClick;
  /** @deprecated */
  disableCloseButton;
  dismissButton;
  fullscreen;
  /** @deprecated */
  heading;
  open;
  theme;
  /** @deprecated */
  close = new EventEmitter();
  dismiss = new EventEmitter();
  motionHiddenEnd = new EventEmitter();
  motionVisibleEnd = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPModal_BaseFactory;
    return function PModal_Factory(__ngFactoryType__) {
      return (ɵPModal_BaseFactory || (ɵPModal_BaseFactory = i0.ɵɵgetInheritedFactory(PModal)))(__ngFactoryType__ || PModal);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PModal,
    selectors: [["p-modal"], ["", "p-modal", ""]],
    inputs: {
      aria: "aria",
      backdrop: "backdrop",
      disableBackdropClick: "disableBackdropClick",
      disableCloseButton: "disableCloseButton",
      dismissButton: "dismissButton",
      fullscreen: "fullscreen",
      heading: "heading",
      open: "open",
      theme: "theme"
    },
    outputs: {
      close: "close",
      dismiss: "dismiss",
      motionHiddenEnd: "motionHiddenEnd",
      motionVisibleEnd: "motionVisibleEnd"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PModal_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PModal, [{
    type: Component,
    args: [{
      selector: 'p-modal,[p-modal]',
      template: '<ng-content />',
      inputs: ['aria', 'backdrop', 'disableBackdropClick', 'disableCloseButton', 'dismissButton', 'fullscreen', 'heading', 'open', 'theme'],
      outputs: ['close', 'dismiss', 'motionHiddenEnd', 'motionVisibleEnd']
    }]
  }], null, null);
})();
class PModelSignature extends BaseComponentWithTheme {
  color;
  fetchPriority;
  lazy;
  model;
  safeZone;
  size;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPModelSignature_BaseFactory;
    return function PModelSignature_Factory(__ngFactoryType__) {
      return (ɵPModelSignature_BaseFactory || (ɵPModelSignature_BaseFactory = i0.ɵɵgetInheritedFactory(PModelSignature)))(__ngFactoryType__ || PModelSignature);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PModelSignature,
    selectors: [["p-model-signature"], ["", "p-model-signature", ""]],
    inputs: {
      color: "color",
      fetchPriority: "fetchPriority",
      lazy: "lazy",
      model: "model",
      safeZone: "safeZone",
      size: "size",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PModelSignature_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PModelSignature, [{
    type: Component,
    args: [{
      selector: 'p-model-signature,[p-model-signature]',
      template: '<ng-content />',
      inputs: ['color', 'fetchPriority', 'lazy', 'model', 'safeZone', 'size', 'theme']
    }]
  }], null, null);
})();
class PMultiSelect extends BaseComponentWithTheme {
  description;
  disabled;
  dropdownDirection;
  hideLabel;
  label;
  message;
  name;
  required;
  state;
  theme;
  value;
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPMultiSelect_BaseFactory;
    return function PMultiSelect_Factory(__ngFactoryType__) {
      return (ɵPMultiSelect_BaseFactory || (ɵPMultiSelect_BaseFactory = i0.ɵɵgetInheritedFactory(PMultiSelect)))(__ngFactoryType__ || PMultiSelect);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PMultiSelect,
    selectors: [["p-multi-select"], ["", "p-multi-select", ""]],
    inputs: {
      description: "description",
      disabled: "disabled",
      dropdownDirection: "dropdownDirection",
      hideLabel: "hideLabel",
      label: "label",
      message: "message",
      name: "name",
      required: "required",
      state: "state",
      theme: "theme",
      value: "value"
    },
    outputs: {
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PMultiSelect_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PMultiSelect, [{
    type: Component,
    args: [{
      selector: 'p-multi-select,[p-multi-select]',
      template: '<ng-content />',
      inputs: ['description', 'disabled', 'dropdownDirection', 'hideLabel', 'label', 'message', 'name', 'required', 'state', 'theme', 'value'],
      outputs: ['update']
    }]
  }], null, null);
})();
class PMultiSelectOption extends BaseComponent {
  disabled;
  value;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPMultiSelectOption_BaseFactory;
    return function PMultiSelectOption_Factory(__ngFactoryType__) {
      return (ɵPMultiSelectOption_BaseFactory || (ɵPMultiSelectOption_BaseFactory = i0.ɵɵgetInheritedFactory(PMultiSelectOption)))(__ngFactoryType__ || PMultiSelectOption);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PMultiSelectOption,
    selectors: [["p-multi-select-option"], ["", "p-multi-select-option", ""]],
    inputs: {
      disabled: "disabled",
      value: "value"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PMultiSelectOption_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PMultiSelectOption, [{
    type: Component,
    args: [{
      selector: 'p-multi-select-option,[p-multi-select-option]',
      template: '<ng-content />',
      inputs: ['disabled', 'value']
    }]
  }], null, null);
})();
class POptgroup extends BaseComponent {
  disabled;
  label;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPOptgroup_BaseFactory;
    return function POptgroup_Factory(__ngFactoryType__) {
      return (ɵPOptgroup_BaseFactory || (ɵPOptgroup_BaseFactory = i0.ɵɵgetInheritedFactory(POptgroup)))(__ngFactoryType__ || POptgroup);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: POptgroup,
    selectors: [["p-optgroup"], ["", "p-optgroup", ""]],
    inputs: {
      disabled: "disabled",
      label: "label"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function POptgroup_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(POptgroup, [{
    type: Component,
    args: [{
      selector: 'p-optgroup,[p-optgroup]',
      template: '<ng-content />',
      inputs: ['disabled', 'label']
    }]
  }], null, null);
})();
class PPagination extends BaseComponentWithTheme {
  activePage;
  /** @deprecated */
  allyLabel;
  /** @deprecated */
  allyLabelNext;
  /** @deprecated */
  allyLabelPage;
  /** @deprecated */
  allyLabelPrev;
  intl;
  itemsPerPage;
  /** @deprecated */
  maxNumberOfPageLinks;
  showLastPage;
  theme;
  totalItemsCount;
  /** @deprecated */
  pageChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPPagination_BaseFactory;
    return function PPagination_Factory(__ngFactoryType__) {
      return (ɵPPagination_BaseFactory || (ɵPPagination_BaseFactory = i0.ɵɵgetInheritedFactory(PPagination)))(__ngFactoryType__ || PPagination);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PPagination,
    selectors: [["p-pagination"], ["", "p-pagination", ""]],
    inputs: {
      activePage: "activePage",
      allyLabel: "allyLabel",
      allyLabelNext: "allyLabelNext",
      allyLabelPage: "allyLabelPage",
      allyLabelPrev: "allyLabelPrev",
      intl: "intl",
      itemsPerPage: "itemsPerPage",
      maxNumberOfPageLinks: "maxNumberOfPageLinks",
      showLastPage: "showLastPage",
      theme: "theme",
      totalItemsCount: "totalItemsCount"
    },
    outputs: {
      pageChange: "pageChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PPagination_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PPagination, [{
    type: Component,
    args: [{
      selector: 'p-pagination,[p-pagination]',
      template: '<ng-content />',
      inputs: ['activePage', 'allyLabel', 'allyLabelNext', 'allyLabelPage', 'allyLabelPrev', 'intl', 'itemsPerPage', 'maxNumberOfPageLinks', 'showLastPage', 'theme', 'totalItemsCount'],
      outputs: ['pageChange', 'update']
    }]
  }], null, null);
})();
class PPinCode extends BaseComponentWithTheme {
  description;
  disabled;
  hideLabel;
  label;
  length;
  loading;
  message;
  name;
  required;
  state;
  theme;
  type;
  value;
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPPinCode_BaseFactory;
    return function PPinCode_Factory(__ngFactoryType__) {
      return (ɵPPinCode_BaseFactory || (ɵPPinCode_BaseFactory = i0.ɵɵgetInheritedFactory(PPinCode)))(__ngFactoryType__ || PPinCode);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PPinCode,
    selectors: [["p-pin-code"], ["", "p-pin-code", ""]],
    inputs: {
      description: "description",
      disabled: "disabled",
      hideLabel: "hideLabel",
      label: "label",
      length: "length",
      loading: "loading",
      message: "message",
      name: "name",
      required: "required",
      state: "state",
      theme: "theme",
      type: "type",
      value: "value"
    },
    outputs: {
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PPinCode_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PPinCode, [{
    type: Component,
    args: [{
      selector: 'p-pin-code,[p-pin-code]',
      template: '<ng-content />',
      inputs: ['description', 'disabled', 'hideLabel', 'label', 'length', 'loading', 'message', 'name', 'required', 'state', 'theme', 'type', 'value'],
      outputs: ['update']
    }]
  }], null, null);
})();
class PPopover extends BaseComponentWithTheme {
  aria;
  description;
  direction;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPPopover_BaseFactory;
    return function PPopover_Factory(__ngFactoryType__) {
      return (ɵPPopover_BaseFactory || (ɵPPopover_BaseFactory = i0.ɵɵgetInheritedFactory(PPopover)))(__ngFactoryType__ || PPopover);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PPopover,
    selectors: [["p-popover"], ["", "p-popover", ""]],
    inputs: {
      aria: "aria",
      description: "description",
      direction: "direction",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PPopover_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PPopover, [{
    type: Component,
    args: [{
      selector: 'p-popover,[p-popover]',
      template: '<ng-content />',
      inputs: ['aria', 'description', 'direction', 'theme']
    }]
  }], null, null);
})();
class PRadioButtonWrapper extends BaseComponentWithTheme {
  hideLabel;
  label;
  loading;
  message;
  state;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPRadioButtonWrapper_BaseFactory;
    return function PRadioButtonWrapper_Factory(__ngFactoryType__) {
      return (ɵPRadioButtonWrapper_BaseFactory || (ɵPRadioButtonWrapper_BaseFactory = i0.ɵɵgetInheritedFactory(PRadioButtonWrapper)))(__ngFactoryType__ || PRadioButtonWrapper);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PRadioButtonWrapper,
    selectors: [["p-radio-button-wrapper"], ["", "p-radio-button-wrapper", ""]],
    inputs: {
      hideLabel: "hideLabel",
      label: "label",
      loading: "loading",
      message: "message",
      state: "state",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PRadioButtonWrapper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PRadioButtonWrapper, [{
    type: Component,
    args: [{
      selector: 'p-radio-button-wrapper,[p-radio-button-wrapper]',
      template: '<ng-content />',
      inputs: ['hideLabel', 'label', 'loading', 'message', 'state', 'theme']
    }]
  }], null, null);
})();
class PScroller extends BaseComponentWithTheme {
  alignScrollIndicator;
  aria;
  gradientColor;
  /** @deprecated */
  gradientColorScheme;
  /** @deprecated */
  scrollIndicatorPosition;
  scrollToPosition;
  scrollbar;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPScroller_BaseFactory;
    return function PScroller_Factory(__ngFactoryType__) {
      return (ɵPScroller_BaseFactory || (ɵPScroller_BaseFactory = i0.ɵɵgetInheritedFactory(PScroller)))(__ngFactoryType__ || PScroller);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PScroller,
    selectors: [["p-scroller"], ["", "p-scroller", ""]],
    inputs: {
      alignScrollIndicator: "alignScrollIndicator",
      aria: "aria",
      gradientColor: "gradientColor",
      gradientColorScheme: "gradientColorScheme",
      scrollIndicatorPosition: "scrollIndicatorPosition",
      scrollToPosition: "scrollToPosition",
      scrollbar: "scrollbar",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PScroller_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PScroller, [{
    type: Component,
    args: [{
      selector: 'p-scroller,[p-scroller]',
      template: '<ng-content />',
      inputs: ['alignScrollIndicator', 'aria', 'gradientColor', 'gradientColorScheme', 'scrollIndicatorPosition', 'scrollToPosition', 'scrollbar', 'theme']
    }]
  }], null, null);
})();
class PSegmentedControl extends BaseComponentWithTheme {
  /** @deprecated */
  backgroundColor;
  columns;
  theme;
  value;
  /** @deprecated */
  segmentedControlChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPSegmentedControl_BaseFactory;
    return function PSegmentedControl_Factory(__ngFactoryType__) {
      return (ɵPSegmentedControl_BaseFactory || (ɵPSegmentedControl_BaseFactory = i0.ɵɵgetInheritedFactory(PSegmentedControl)))(__ngFactoryType__ || PSegmentedControl);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PSegmentedControl,
    selectors: [["p-segmented-control"], ["", "p-segmented-control", ""]],
    inputs: {
      backgroundColor: "backgroundColor",
      columns: "columns",
      theme: "theme",
      value: "value"
    },
    outputs: {
      segmentedControlChange: "segmentedControlChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PSegmentedControl_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PSegmentedControl, [{
    type: Component,
    args: [{
      selector: 'p-segmented-control,[p-segmented-control]',
      template: '<ng-content />',
      inputs: ['backgroundColor', 'columns', 'theme', 'value'],
      outputs: ['segmentedControlChange', 'update']
    }]
  }], null, null);
})();
class PSegmentedControlItem extends BaseComponent {
  aria;
  disabled;
  icon;
  iconSource;
  label;
  value;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPSegmentedControlItem_BaseFactory;
    return function PSegmentedControlItem_Factory(__ngFactoryType__) {
      return (ɵPSegmentedControlItem_BaseFactory || (ɵPSegmentedControlItem_BaseFactory = i0.ɵɵgetInheritedFactory(PSegmentedControlItem)))(__ngFactoryType__ || PSegmentedControlItem);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PSegmentedControlItem,
    selectors: [["p-segmented-control-item"], ["", "p-segmented-control-item", ""]],
    inputs: {
      aria: "aria",
      disabled: "disabled",
      icon: "icon",
      iconSource: "iconSource",
      label: "label",
      value: "value"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PSegmentedControlItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PSegmentedControlItem, [{
    type: Component,
    args: [{
      selector: 'p-segmented-control-item,[p-segmented-control-item]',
      template: '<ng-content />',
      inputs: ['aria', 'disabled', 'icon', 'iconSource', 'label', 'value']
    }]
  }], null, null);
})();
class PSelect extends BaseComponentWithTheme {
  description;
  disabled;
  dropdownDirection;
  hideLabel;
  label;
  message;
  name;
  required;
  state;
  theme;
  value;
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPSelect_BaseFactory;
    return function PSelect_Factory(__ngFactoryType__) {
      return (ɵPSelect_BaseFactory || (ɵPSelect_BaseFactory = i0.ɵɵgetInheritedFactory(PSelect)))(__ngFactoryType__ || PSelect);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PSelect,
    selectors: [["p-select"], ["", "p-select", ""]],
    inputs: {
      description: "description",
      disabled: "disabled",
      dropdownDirection: "dropdownDirection",
      hideLabel: "hideLabel",
      label: "label",
      message: "message",
      name: "name",
      required: "required",
      state: "state",
      theme: "theme",
      value: "value"
    },
    outputs: {
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PSelect_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PSelect, [{
    type: Component,
    args: [{
      selector: 'p-select,[p-select]',
      template: '<ng-content />',
      inputs: ['description', 'disabled', 'dropdownDirection', 'hideLabel', 'label', 'message', 'name', 'required', 'state', 'theme', 'value'],
      outputs: ['update']
    }]
  }], null, null);
})();
class PSelectOption extends BaseComponent {
  disabled;
  value;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPSelectOption_BaseFactory;
    return function PSelectOption_Factory(__ngFactoryType__) {
      return (ɵPSelectOption_BaseFactory || (ɵPSelectOption_BaseFactory = i0.ɵɵgetInheritedFactory(PSelectOption)))(__ngFactoryType__ || PSelectOption);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PSelectOption,
    selectors: [["p-select-option"], ["", "p-select-option", ""]],
    inputs: {
      disabled: "disabled",
      value: "value"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PSelectOption_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PSelectOption, [{
    type: Component,
    args: [{
      selector: 'p-select-option,[p-select-option]',
      template: '<ng-content />',
      inputs: ['disabled', 'value']
    }]
  }], null, null);
})();
class PSelectWrapper extends BaseComponentWithTheme {
  description;
  dropdownDirection;
  filter;
  hideLabel;
  label;
  message;
  native;
  state;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPSelectWrapper_BaseFactory;
    return function PSelectWrapper_Factory(__ngFactoryType__) {
      return (ɵPSelectWrapper_BaseFactory || (ɵPSelectWrapper_BaseFactory = i0.ɵɵgetInheritedFactory(PSelectWrapper)))(__ngFactoryType__ || PSelectWrapper);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PSelectWrapper,
    selectors: [["p-select-wrapper"], ["", "p-select-wrapper", ""]],
    inputs: {
      description: "description",
      dropdownDirection: "dropdownDirection",
      filter: "filter",
      hideLabel: "hideLabel",
      label: "label",
      message: "message",
      native: "native",
      state: "state",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PSelectWrapper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PSelectWrapper, [{
    type: Component,
    args: [{
      selector: 'p-select-wrapper,[p-select-wrapper]',
      template: '<ng-content />',
      inputs: ['description', 'dropdownDirection', 'filter', 'hideLabel', 'label', 'message', 'native', 'state', 'theme']
    }]
  }], null, null);
})();
class PSpinner extends BaseComponentWithTheme {
  aria;
  size;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPSpinner_BaseFactory;
    return function PSpinner_Factory(__ngFactoryType__) {
      return (ɵPSpinner_BaseFactory || (ɵPSpinner_BaseFactory = i0.ɵɵgetInheritedFactory(PSpinner)))(__ngFactoryType__ || PSpinner);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PSpinner,
    selectors: [["p-spinner"], ["", "p-spinner", ""]],
    inputs: {
      aria: "aria",
      size: "size",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PSpinner_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PSpinner, [{
    type: Component,
    args: [{
      selector: 'p-spinner,[p-spinner]',
      template: '<ng-content />',
      inputs: ['aria', 'size', 'theme']
    }]
  }], null, null);
})();
class PStepperHorizontal extends BaseComponentWithTheme {
  size;
  theme;
  /** @deprecated */
  stepChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPStepperHorizontal_BaseFactory;
    return function PStepperHorizontal_Factory(__ngFactoryType__) {
      return (ɵPStepperHorizontal_BaseFactory || (ɵPStepperHorizontal_BaseFactory = i0.ɵɵgetInheritedFactory(PStepperHorizontal)))(__ngFactoryType__ || PStepperHorizontal);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PStepperHorizontal,
    selectors: [["p-stepper-horizontal"], ["", "p-stepper-horizontal", ""]],
    inputs: {
      size: "size",
      theme: "theme"
    },
    outputs: {
      stepChange: "stepChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PStepperHorizontal_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PStepperHorizontal, [{
    type: Component,
    args: [{
      selector: 'p-stepper-horizontal,[p-stepper-horizontal]',
      template: '<ng-content />',
      inputs: ['size', 'theme'],
      outputs: ['stepChange', 'update']
    }]
  }], null, null);
})();
class PStepperHorizontalItem extends BaseComponent {
  disabled;
  state;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPStepperHorizontalItem_BaseFactory;
    return function PStepperHorizontalItem_Factory(__ngFactoryType__) {
      return (ɵPStepperHorizontalItem_BaseFactory || (ɵPStepperHorizontalItem_BaseFactory = i0.ɵɵgetInheritedFactory(PStepperHorizontalItem)))(__ngFactoryType__ || PStepperHorizontalItem);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PStepperHorizontalItem,
    selectors: [["p-stepper-horizontal-item"], ["", "p-stepper-horizontal-item", ""]],
    inputs: {
      disabled: "disabled",
      state: "state"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PStepperHorizontalItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PStepperHorizontalItem, [{
    type: Component,
    args: [{
      selector: 'p-stepper-horizontal-item,[p-stepper-horizontal-item]',
      template: '<ng-content />',
      inputs: ['disabled', 'state']
    }]
  }], null, null);
})();
class PSwitch extends BaseComponentWithTheme {
  alignLabel;
  checked;
  disabled;
  hideLabel;
  loading;
  stretch;
  theme;
  /** @deprecated */
  switchChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPSwitch_BaseFactory;
    return function PSwitch_Factory(__ngFactoryType__) {
      return (ɵPSwitch_BaseFactory || (ɵPSwitch_BaseFactory = i0.ɵɵgetInheritedFactory(PSwitch)))(__ngFactoryType__ || PSwitch);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PSwitch,
    selectors: [["p-switch"], ["", "p-switch", ""]],
    inputs: {
      alignLabel: "alignLabel",
      checked: "checked",
      disabled: "disabled",
      hideLabel: "hideLabel",
      loading: "loading",
      stretch: "stretch",
      theme: "theme"
    },
    outputs: {
      switchChange: "switchChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PSwitch_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PSwitch, [{
    type: Component,
    args: [{
      selector: 'p-switch,[p-switch]',
      template: '<ng-content />',
      inputs: ['alignLabel', 'checked', 'disabled', 'hideLabel', 'loading', 'stretch', 'theme'],
      outputs: ['switchChange', 'update']
    }]
  }], null, null);
})();
class PTable extends BaseComponentWithTheme {
  caption;
  theme;
  /** @deprecated */
  sortingChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTable_BaseFactory;
    return function PTable_Factory(__ngFactoryType__) {
      return (ɵPTable_BaseFactory || (ɵPTable_BaseFactory = i0.ɵɵgetInheritedFactory(PTable)))(__ngFactoryType__ || PTable);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTable,
    selectors: [["p-table"], ["", "p-table", ""]],
    inputs: {
      caption: "caption",
      theme: "theme"
    },
    outputs: {
      sortingChange: "sortingChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTable_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTable, [{
    type: Component,
    args: [{
      selector: 'p-table,[p-table]',
      template: '<ng-content />',
      inputs: ['caption', 'theme'],
      outputs: ['sortingChange', 'update']
    }]
  }], null, null);
})();
class PTableBody extends BaseComponent {
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTableBody_BaseFactory;
    return function PTableBody_Factory(__ngFactoryType__) {
      return (ɵPTableBody_BaseFactory || (ɵPTableBody_BaseFactory = i0.ɵɵgetInheritedFactory(PTableBody)))(__ngFactoryType__ || PTableBody);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTableBody,
    selectors: [["p-table-body"], ["", "p-table-body", ""]],
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTableBody_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTableBody, [{
    type: Component,
    args: [{
      selector: 'p-table-body,[p-table-body]',
      template: '<ng-content />'
    }]
  }], null, null);
})();
class PTableCell extends BaseComponent {
  multiline;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTableCell_BaseFactory;
    return function PTableCell_Factory(__ngFactoryType__) {
      return (ɵPTableCell_BaseFactory || (ɵPTableCell_BaseFactory = i0.ɵɵgetInheritedFactory(PTableCell)))(__ngFactoryType__ || PTableCell);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTableCell,
    selectors: [["p-table-cell"], ["", "p-table-cell", ""]],
    inputs: {
      multiline: "multiline"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTableCell_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTableCell, [{
    type: Component,
    args: [{
      selector: 'p-table-cell,[p-table-cell]',
      template: '<ng-content />',
      inputs: ['multiline']
    }]
  }], null, null);
})();
class PTableHead extends BaseComponent {
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTableHead_BaseFactory;
    return function PTableHead_Factory(__ngFactoryType__) {
      return (ɵPTableHead_BaseFactory || (ɵPTableHead_BaseFactory = i0.ɵɵgetInheritedFactory(PTableHead)))(__ngFactoryType__ || PTableHead);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTableHead,
    selectors: [["p-table-head"], ["", "p-table-head", ""]],
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTableHead_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTableHead, [{
    type: Component,
    args: [{
      selector: 'p-table-head,[p-table-head]',
      template: '<ng-content />'
    }]
  }], null, null);
})();
class PTableHeadCell extends BaseComponent {
  hideLabel;
  multiline;
  sort;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTableHeadCell_BaseFactory;
    return function PTableHeadCell_Factory(__ngFactoryType__) {
      return (ɵPTableHeadCell_BaseFactory || (ɵPTableHeadCell_BaseFactory = i0.ɵɵgetInheritedFactory(PTableHeadCell)))(__ngFactoryType__ || PTableHeadCell);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTableHeadCell,
    selectors: [["p-table-head-cell"], ["", "p-table-head-cell", ""]],
    inputs: {
      hideLabel: "hideLabel",
      multiline: "multiline",
      sort: "sort"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTableHeadCell_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTableHeadCell, [{
    type: Component,
    args: [{
      selector: 'p-table-head-cell,[p-table-head-cell]',
      template: '<ng-content />',
      inputs: ['hideLabel', 'multiline', 'sort']
    }]
  }], null, null);
})();
class PTableHeadRow extends BaseComponent {
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTableHeadRow_BaseFactory;
    return function PTableHeadRow_Factory(__ngFactoryType__) {
      return (ɵPTableHeadRow_BaseFactory || (ɵPTableHeadRow_BaseFactory = i0.ɵɵgetInheritedFactory(PTableHeadRow)))(__ngFactoryType__ || PTableHeadRow);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTableHeadRow,
    selectors: [["p-table-head-row"], ["", "p-table-head-row", ""]],
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTableHeadRow_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTableHeadRow, [{
    type: Component,
    args: [{
      selector: 'p-table-head-row,[p-table-head-row]',
      template: '<ng-content />'
    }]
  }], null, null);
})();
class PTableRow extends BaseComponent {
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTableRow_BaseFactory;
    return function PTableRow_Factory(__ngFactoryType__) {
      return (ɵPTableRow_BaseFactory || (ɵPTableRow_BaseFactory = i0.ɵɵgetInheritedFactory(PTableRow)))(__ngFactoryType__ || PTableRow);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTableRow,
    selectors: [["p-table-row"], ["", "p-table-row", ""]],
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTableRow_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTableRow, [{
    type: Component,
    args: [{
      selector: 'p-table-row,[p-table-row]',
      template: '<ng-content />'
    }]
  }], null, null);
})();
class PTabs extends BaseComponentWithTheme {
  activeTabIndex;
  gradientColor;
  /** @deprecated */
  gradientColorScheme;
  size;
  theme;
  weight;
  /** @deprecated */
  tabChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTabs_BaseFactory;
    return function PTabs_Factory(__ngFactoryType__) {
      return (ɵPTabs_BaseFactory || (ɵPTabs_BaseFactory = i0.ɵɵgetInheritedFactory(PTabs)))(__ngFactoryType__ || PTabs);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTabs,
    selectors: [["p-tabs"], ["", "p-tabs", ""]],
    inputs: {
      activeTabIndex: "activeTabIndex",
      gradientColor: "gradientColor",
      gradientColorScheme: "gradientColorScheme",
      size: "size",
      theme: "theme",
      weight: "weight"
    },
    outputs: {
      tabChange: "tabChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTabs_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTabs, [{
    type: Component,
    args: [{
      selector: 'p-tabs,[p-tabs]',
      template: '<ng-content />',
      inputs: ['activeTabIndex', 'gradientColor', 'gradientColorScheme', 'size', 'theme', 'weight'],
      outputs: ['tabChange', 'update']
    }]
  }], null, null);
})();
class PTabsBar extends BaseComponentWithTheme {
  activeTabIndex;
  gradientColor;
  /** @deprecated */
  gradientColorScheme;
  size;
  theme;
  weight;
  /** @deprecated */
  tabChange = new EventEmitter();
  update = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTabsBar_BaseFactory;
    return function PTabsBar_Factory(__ngFactoryType__) {
      return (ɵPTabsBar_BaseFactory || (ɵPTabsBar_BaseFactory = i0.ɵɵgetInheritedFactory(PTabsBar)))(__ngFactoryType__ || PTabsBar);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTabsBar,
    selectors: [["p-tabs-bar"], ["", "p-tabs-bar", ""]],
    inputs: {
      activeTabIndex: "activeTabIndex",
      gradientColor: "gradientColor",
      gradientColorScheme: "gradientColorScheme",
      size: "size",
      theme: "theme",
      weight: "weight"
    },
    outputs: {
      tabChange: "tabChange",
      update: "update"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTabsBar_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTabsBar, [{
    type: Component,
    args: [{
      selector: 'p-tabs-bar,[p-tabs-bar]',
      template: '<ng-content />',
      inputs: ['activeTabIndex', 'gradientColor', 'gradientColorScheme', 'size', 'theme', 'weight'],
      outputs: ['tabChange', 'update']
    }]
  }], null, null);
})();
class PTabsItem extends BaseComponent {
  label;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTabsItem_BaseFactory;
    return function PTabsItem_Factory(__ngFactoryType__) {
      return (ɵPTabsItem_BaseFactory || (ɵPTabsItem_BaseFactory = i0.ɵɵgetInheritedFactory(PTabsItem)))(__ngFactoryType__ || PTabsItem);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTabsItem,
    selectors: [["p-tabs-item"], ["", "p-tabs-item", ""]],
    inputs: {
      label: "label"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTabsItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTabsItem, [{
    type: Component,
    args: [{
      selector: 'p-tabs-item,[p-tabs-item]',
      template: '<ng-content />',
      inputs: ['label']
    }]
  }], null, null);
})();
class PTag extends BaseComponentWithTheme {
  color;
  compact;
  icon;
  iconSource;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTag_BaseFactory;
    return function PTag_Factory(__ngFactoryType__) {
      return (ɵPTag_BaseFactory || (ɵPTag_BaseFactory = i0.ɵɵgetInheritedFactory(PTag)))(__ngFactoryType__ || PTag);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTag,
    selectors: [["p-tag"], ["", "p-tag", ""]],
    inputs: {
      color: "color",
      compact: "compact",
      icon: "icon",
      iconSource: "iconSource",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTag_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTag, [{
    type: Component,
    args: [{
      selector: 'p-tag,[p-tag]',
      template: '<ng-content />',
      inputs: ['color', 'compact', 'icon', 'iconSource', 'theme']
    }]
  }], null, null);
})();
class PTagDismissible extends BaseComponentWithTheme {
  aria;
  color;
  label;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTagDismissible_BaseFactory;
    return function PTagDismissible_Factory(__ngFactoryType__) {
      return (ɵPTagDismissible_BaseFactory || (ɵPTagDismissible_BaseFactory = i0.ɵɵgetInheritedFactory(PTagDismissible)))(__ngFactoryType__ || PTagDismissible);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTagDismissible,
    selectors: [["p-tag-dismissible"], ["", "p-tag-dismissible", ""]],
    inputs: {
      aria: "aria",
      color: "color",
      label: "label",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTagDismissible_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTagDismissible, [{
    type: Component,
    args: [{
      selector: 'p-tag-dismissible,[p-tag-dismissible]',
      template: '<ng-content />',
      inputs: ['aria', 'color', 'label', 'theme']
    }]
  }], null, null);
})();
class PText extends BaseComponentWithTheme {
  align;
  color;
  ellipsis;
  size;
  tag;
  theme;
  weight;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPText_BaseFactory;
    return function PText_Factory(__ngFactoryType__) {
      return (ɵPText_BaseFactory || (ɵPText_BaseFactory = i0.ɵɵgetInheritedFactory(PText)))(__ngFactoryType__ || PText);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PText,
    selectors: [["p-text"], ["", "p-text", ""]],
    inputs: {
      align: "align",
      color: "color",
      ellipsis: "ellipsis",
      size: "size",
      tag: "tag",
      theme: "theme",
      weight: "weight"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PText_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PText, [{
    type: Component,
    args: [{
      selector: 'p-text,[p-text]',
      template: '<ng-content />',
      inputs: ['align', 'color', 'ellipsis', 'size', 'tag', 'theme', 'weight']
    }]
  }], null, null);
})();
class PTextFieldWrapper extends BaseComponentWithTheme {
  actionIcon;
  actionLoading;
  description;
  hideLabel;
  label;
  message;
  /** @deprecated */
  showCharacterCount;
  showCounter;
  showPasswordToggle;
  state;
  submitButton;
  theme;
  unit;
  unitPosition;
  action = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTextFieldWrapper_BaseFactory;
    return function PTextFieldWrapper_Factory(__ngFactoryType__) {
      return (ɵPTextFieldWrapper_BaseFactory || (ɵPTextFieldWrapper_BaseFactory = i0.ɵɵgetInheritedFactory(PTextFieldWrapper)))(__ngFactoryType__ || PTextFieldWrapper);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTextFieldWrapper,
    selectors: [["p-text-field-wrapper"], ["", "p-text-field-wrapper", ""]],
    inputs: {
      actionIcon: "actionIcon",
      actionLoading: "actionLoading",
      description: "description",
      hideLabel: "hideLabel",
      label: "label",
      message: "message",
      showCharacterCount: "showCharacterCount",
      showCounter: "showCounter",
      showPasswordToggle: "showPasswordToggle",
      state: "state",
      submitButton: "submitButton",
      theme: "theme",
      unit: "unit",
      unitPosition: "unitPosition"
    },
    outputs: {
      action: "action"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTextFieldWrapper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTextFieldWrapper, [{
    type: Component,
    args: [{
      selector: 'p-text-field-wrapper,[p-text-field-wrapper]',
      template: '<ng-content />',
      inputs: ['actionIcon', 'actionLoading', 'description', 'hideLabel', 'label', 'message', 'showCharacterCount', 'showCounter', 'showPasswordToggle', 'state', 'submitButton', 'theme', 'unit', 'unitPosition'],
      outputs: ['action']
    }]
  }], null, null);
})();
class PTextList extends BaseComponentWithTheme {
  /** @deprecated */
  listType;
  /** @deprecated */
  orderType;
  theme;
  type;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTextList_BaseFactory;
    return function PTextList_Factory(__ngFactoryType__) {
      return (ɵPTextList_BaseFactory || (ɵPTextList_BaseFactory = i0.ɵɵgetInheritedFactory(PTextList)))(__ngFactoryType__ || PTextList);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTextList,
    selectors: [["p-text-list"], ["", "p-text-list", ""]],
    inputs: {
      listType: "listType",
      orderType: "orderType",
      theme: "theme",
      type: "type"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTextList_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTextList, [{
    type: Component,
    args: [{
      selector: 'p-text-list,[p-text-list]',
      template: '<ng-content />',
      inputs: ['listType', 'orderType', 'theme', 'type']
    }]
  }], null, null);
})();
class PTextListItem extends BaseComponent {
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTextListItem_BaseFactory;
    return function PTextListItem_Factory(__ngFactoryType__) {
      return (ɵPTextListItem_BaseFactory || (ɵPTextListItem_BaseFactory = i0.ɵɵgetInheritedFactory(PTextListItem)))(__ngFactoryType__ || PTextListItem);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTextListItem,
    selectors: [["p-text-list-item"], ["", "p-text-list-item", ""]],
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTextListItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTextListItem, [{
    type: Component,
    args: [{
      selector: 'p-text-list-item,[p-text-list-item]',
      template: '<ng-content />'
    }]
  }], null, null);
})();
class PTextarea extends BaseComponentWithTheme {
  autoComplete;
  description;
  disabled;
  form;
  hideLabel;
  label;
  maxLength;
  message;
  minLength;
  name;
  placeholder;
  readOnly;
  required;
  resize;
  rows;
  showCounter;
  spellCheck;
  state;
  theme;
  value;
  wrap;
  blur = new EventEmitter();
  change = new EventEmitter();
  input = new EventEmitter();
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTextarea_BaseFactory;
    return function PTextarea_Factory(__ngFactoryType__) {
      return (ɵPTextarea_BaseFactory || (ɵPTextarea_BaseFactory = i0.ɵɵgetInheritedFactory(PTextarea)))(__ngFactoryType__ || PTextarea);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTextarea,
    selectors: [["p-textarea"], ["", "p-textarea", ""]],
    inputs: {
      autoComplete: "autoComplete",
      description: "description",
      disabled: "disabled",
      form: "form",
      hideLabel: "hideLabel",
      label: "label",
      maxLength: "maxLength",
      message: "message",
      minLength: "minLength",
      name: "name",
      placeholder: "placeholder",
      readOnly: "readOnly",
      required: "required",
      resize: "resize",
      rows: "rows",
      showCounter: "showCounter",
      spellCheck: "spellCheck",
      state: "state",
      theme: "theme",
      value: "value",
      wrap: "wrap"
    },
    outputs: {
      blur: "blur",
      change: "change",
      input: "input"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTextarea_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTextarea, [{
    type: Component,
    args: [{
      selector: 'p-textarea,[p-textarea]',
      template: '<ng-content />',
      inputs: ['autoComplete', 'description', 'disabled', 'form', 'hideLabel', 'label', 'maxLength', 'message', 'minLength', 'name', 'placeholder', 'readOnly', 'required', 'resize', 'rows', 'showCounter', 'spellCheck', 'state', 'theme', 'value', 'wrap'],
      outputs: ['blur', 'change', 'input']
    }]
  }], null, null);
})();
class PTextareaWrapper extends BaseComponentWithTheme {
  description;
  hideLabel;
  label;
  message;
  /** @deprecated */
  showCharacterCount;
  showCounter;
  state;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPTextareaWrapper_BaseFactory;
    return function PTextareaWrapper_Factory(__ngFactoryType__) {
      return (ɵPTextareaWrapper_BaseFactory || (ɵPTextareaWrapper_BaseFactory = i0.ɵɵgetInheritedFactory(PTextareaWrapper)))(__ngFactoryType__ || PTextareaWrapper);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PTextareaWrapper,
    selectors: [["p-textarea-wrapper"], ["", "p-textarea-wrapper", ""]],
    inputs: {
      description: "description",
      hideLabel: "hideLabel",
      label: "label",
      message: "message",
      showCharacterCount: "showCharacterCount",
      showCounter: "showCounter",
      state: "state",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PTextareaWrapper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PTextareaWrapper, [{
    type: Component,
    args: [{
      selector: 'p-textarea-wrapper,[p-textarea-wrapper]',
      template: '<ng-content />',
      inputs: ['description', 'hideLabel', 'label', 'message', 'showCharacterCount', 'showCounter', 'state', 'theme']
    }]
  }], null, null);
})();
class PToast extends BaseComponentWithTheme {
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPToast_BaseFactory;
    return function PToast_Factory(__ngFactoryType__) {
      return (ɵPToast_BaseFactory || (ɵPToast_BaseFactory = i0.ɵɵgetInheritedFactory(PToast)))(__ngFactoryType__ || PToast);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PToast,
    selectors: [["p-toast"], ["", "p-toast", ""]],
    inputs: {
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PToast_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PToast, [{
    type: Component,
    args: [{
      selector: 'p-toast,[p-toast]',
      template: '<ng-content />',
      inputs: ['theme']
    }]
  }], null, null);
})();
class PWordmark extends BaseComponentWithTheme {
  aria;
  href;
  size;
  target;
  theme;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵPWordmark_BaseFactory;
    return function PWordmark_Factory(__ngFactoryType__) {
      return (ɵPWordmark_BaseFactory || (ɵPWordmark_BaseFactory = i0.ɵɵgetInheritedFactory(PWordmark)))(__ngFactoryType__ || PWordmark);
    };
  })();
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: PWordmark,
    selectors: [["p-wordmark"], ["", "p-wordmark", ""]],
    inputs: {
      aria: "aria",
      href: "href",
      size: "size",
      target: "target",
      theme: "theme"
    },
    features: [i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function PWordmark_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PWordmark, [{
    type: Component,
    args: [{
      selector: 'p-wordmark,[p-wordmark]',
      template: '<ng-content />',
      inputs: ['aria', 'href', 'size', 'target', 'theme']
    }]
  }], null, null);
})();
const DECLARATIONS = [PAccordion, PBanner, PButton, PButtonGroup, PButtonPure, PButtonTile, PCanvas, PCarousel, PCheckbox, PCheckboxWrapper, PContentWrapper, PCrest, PDisplay, PDivider, PFieldset, PFieldsetWrapper, PFlex, PFlexItem, PFlyout, PFlyoutMultilevel, PFlyoutMultilevelItem, PGrid, PGridItem, PHeading, PHeadline, PIcon, PInlineNotification, PLink, PLinkPure, PLinkSocial, PLinkTile, PLinkTileModelSignature, PLinkTileProduct, PMarque, PModal, PModelSignature, PMultiSelect, PMultiSelectOption, POptgroup, PPagination, PPinCode, PPopover, PRadioButtonWrapper, PScroller, PSegmentedControl, PSegmentedControlItem, PSelect, PSelectOption, PSelectWrapper, PSpinner, PStepperHorizontal, PStepperHorizontalItem, PSwitch, PTable, PTableBody, PTableCell, PTableHead, PTableHeadCell, PTableHeadRow, PTableRow, PTabs, PTabsBar, PTabsItem, PTag, PTagDismissible, PText, PTextFieldWrapper, PTextList, PTextListItem, PTextarea, PTextareaWrapper, PToast, PWordmark];
class DefaultConfig {
  prefix = '';
  theme; // since theme exists on almost every component, it is defined here kind of like a global prop
}
// TODO: unit tests are missing
class PorscheDesignSystemModule {
  theme$ = inject(THEME_TOKEN);
  constructor(configParam) {
    const configs = configParam || [new DefaultConfig()];
    this.theme$.next(configs[0].theme || 'light'); // first config sets the theme
    configs.forEach(load);
  }
  static load(config) {
    return {
      ngModule: PorscheDesignSystemModule,
      providers: [{
        provide: DefaultConfig,
        multi: true,
        // to support multiple prefixes in same module
        useValue: config
      }]
    };
  }
  static ɵfac = function PorscheDesignSystemModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PorscheDesignSystemModule)(i0.ɵɵinject(DefaultConfig, 8));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: PorscheDesignSystemModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [{
      provide: THEME_TOKEN,
      useValue: new BehaviorSubject('light')
    }]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PorscheDesignSystemModule, [{
    type: NgModule,
    args: [{
      declarations: DECLARATIONS,
      exports: DECLARATIONS,
      providers: [{
        provide: THEME_TOKEN,
        useValue: new BehaviorSubject('light')
      }]
    }]
  }], () => [{
    type: DefaultConfig,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class ToastManager {
  addMessage(message) {
    const toast = document.body.querySelector('p-toast,[p-toast]');
    customElements.whenDefined(toast.tagName.toLowerCase()).then(() => toast.addMessage(message));
  }
  static ɵfac = function ToastManager_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ToastManager)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ToastManager,
    factory: ToastManager.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastManager, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DECLARATIONS, PAccordion, PBanner, PButton, PButtonGroup, PButtonPure, PButtonTile, PCanvas, PCarousel, PCheckbox, PCheckboxWrapper, PContentWrapper, PCrest, PDisplay, PDivider, PFieldset, PFieldsetWrapper, PFlex, PFlexItem, PFlyout, PFlyoutMultilevel, PFlyoutMultilevelItem, PGrid, PGridItem, PHeading, PHeadline, PIcon, PInlineNotification, PLink, PLinkPure, PLinkSocial, PLinkTile, PLinkTileModelSignature, PLinkTileProduct, PMarque, PModal, PModelSignature, PMultiSelect, PMultiSelectOption, POptgroup, PPagination, PPinCode, PPopover, PRadioButtonWrapper, PScroller, PSegmentedControl, PSegmentedControlItem, PSelect, PSelectOption, PSelectWrapper, PSpinner, PStepperHorizontal, PStepperHorizontalItem, PSwitch, PTable, PTableBody, PTableCell, PTableHead, PTableHeadCell, PTableHeadRow, PTableRow, PTabs, PTabsBar, PTabsItem, PTag, PTagDismissible, PText, PTextFieldWrapper, PTextList, PTextListItem, PTextarea, PTextareaWrapper, PToast, PWordmark, PorscheDesignSystemModule, THEME_TOKEN, ToastManager };
