import {Injectable} from "@angular/core";
import { map, Observable } from "rxjs";
import {Service} from "../model/service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import { Lambda } from "../model/lambda";


@Injectable({
  providedIn: "root",
})
export class StatusService {

  private readonly SERVICE_STATUS_URL: string = environment.apiBaseUrl + '/service-status';
  private readonly LAMBDAS_URL: string = environment.apiBaseUrl + '/lambdas';


  constructor(private httpClient: HttpClient) {
  }

  public getServiceHealth(): Observable<Service[]> {
    return this.httpClient.get<Service[]>(`${this.SERVICE_STATUS_URL}`);
  }

  public getLambdas(): Observable<Lambda[]> {
    return this.httpClient.get<Lambda[]>(`${this.LAMBDAS_URL}`).pipe(
      map((lambdas: Lambda[]) => lambdas.sort((firstLambda: Lambda, secondLambda: Lambda) => firstLambda.name.localeCompare(secondLambda.name)))
    );
  }
}
