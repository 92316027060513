export const environment = {
  production: false,
  apiBaseUrl: "https://service-manager-backend.dev.services-pfs.vwfs.io",
  issuerUri: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_SrrNPr3ME",
  redirectUri: "https://service-manager.dev.services-pfs.vwfs.io/dashboard",
  clientId: '2kop5i4djh129o7pd6lpfo7jng',
  responseType: 'code',
  scope: 'openid email profile',
  identityProvider: "Entra-Id-IdentityProvider",
};
