import {Component} from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavContainer, MatSidenavModule} from "@angular/material/sidenav";
import {MatMenu} from "@angular/material/menu";
import {MatNavList} from "@angular/material/list";
import {PorscheDesignSystemModule} from "@porsche-design-system/components-angular";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatCardModule, MatButtonModule, MatSidenavContainer, MatMenu, MatNavList, MatSidenavModule, RouterLink, PorscheDesignSystemModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Service Manager';
}

