import {Injectable} from "@angular/core";
import { map, Observable, switchMap } from "rxjs";
import {Service} from "../model/service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {environment} from "../../../environments/environment";
import { Lambda } from "../model/lambda";
import { DashboardResult } from "../model/dashboard-result";
import { OidcSecurityService } from "angular-auth-oidc-client";


@Injectable({
  providedIn: "root",
})
export class StatusService {

  private readonly SERVICE_STATUS_URL: string = environment.apiBaseUrl + '/dashboard';

  constructor(private httpClient: HttpClient, private oidcService: OidcSecurityService) {
  }

  public getServicesAndLambdas(): Observable<DashboardResult> {
    return this.httpClient.get<DashboardResult>(`${this.SERVICE_STATUS_URL}`);
  }
}
