import {Component, Input} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";

import {MatCardModule} from "@angular/material/card";
import {Service} from "../model/service";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-service-status',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatIconModule,
    MatTooltip
  ],
  templateUrl: './service-status.component.html',

  styleUrl: './service-status.component.scss'
})
export class ServiceStatusComponent {

  @Input()
  service: Service | undefined;
  @Input()
  serviceName: string | undefined;
}
