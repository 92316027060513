import { Component, OnInit } from '@angular/core';
import { MatCardModule } from "@angular/material/card";
import { StatusService } from "./status.service";

import { MatIconModule } from "@angular/material/icon";
import { ServiceStatusComponent } from "../service-status/service-status.component";
import { PorscheDesignSystemModule } from "@porsche-design-system/components-angular";
import { MatTab, MatTabGroup } from "@angular/material/tabs";
import { DashboardResult } from "../model/dashboard-result";

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatIconModule,
    ServiceStatusComponent,
    PorscheDesignSystemModule,
    MatTabGroup,
    MatTab
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent implements OnInit {

  public dashboardResult: DashboardResult = Object.assign({});
  public showTitle: boolean = false;
  public loading: boolean = true;

  constructor(private healthService: StatusService) {
  }

  ngOnInit(): void {
    this.healthService.getServicesAndLambdas().subscribe(result => {
      this.dashboardResult = result;
      this.showTitle = true;
      this.loading = false;
    });
  }

  public openApiDocumentation(apiDocumentationUrl: string): void {
    window.open(apiDocumentationUrl, '_blank');
  }
}
