var e = {
    d: (t, n) => {
      for (var o in n) e.o(n, o) && !e.o(t, o) && Object.defineProperty(t, o, {
        enumerable: !0,
        get: n[o]
      });
    },
    o: (e, t) => Object.prototype.hasOwnProperty.call(e, t)
  },
  t = {};
e.d(t, {
  O: () => i,
  H: () => r
});
const n = "porscheDesignSystem";
function o() {
  return document[n] || (document[n] = {}), document[n];
}
function s({
  script: e,
  version: t,
  prefix: s
}) {
  const r = function (e) {
      const t = o(),
        {
          [e]: n
        } = t;
      if (!n) {
        let n = () => {};
        const o = new Promise(e => n = e);
        t[e] = {
          isInjected: !1,
          isReady: () => o,
          readyResolve: n,
          prefixes: [],
          registerCustomElements: null
        };
      }
      return t[e];
    }(t),
    {
      isInjected: c,
      prefixes: i = [],
      registerCustomElements: d
    } = r,
    [m] = Object.entries(o()).filter(([e, n]) => e !== t && "object" == typeof n && n.prefixes.includes(s));
  if (m) throw new Error(`[Porsche Design System v${t}] prefix '${s}' is already registered with version '${m[0]}' of the Porsche Design System. Please use a different one.\nTake a look at document.${n} for more details.`);
  c || (function (e) {
    const t = document.createElement("script");
    t.src = e, t.setAttribute("crossorigin", ""), document.body.appendChild(t);
  }(e), r.isInjected = !0), i.includes(s) || (i.push(s), d && d(s));
}
const r = (e = {}) => {
    const t = "PORSCHE_DESIGN_SYSTEM_CDN";
    window[t] = e.cdn || window[t] || (window.location.origin.match(/\.cn$/) ? "cn" : "auto");
    const n = "porscheDesignSystem";
    document[n] || (document[n] = {}), document[n].cdn = {
      url: "https://cdn.ui.porsche." + ("cn" === window[t] ? "cn" : "com"),
      prefixes: []
    }, s({
      version: "3.20.0",
      script: document[n].cdn.url + "/porsche-design-system/components/porsche-design-system.v3.20.0.08692c0dd248f2aff6d8.js",
      prefix: e.prefix || ""
    });
  },
  c = {
    loading: 0,
    interactive: 1,
    complete: 2
  },
  i = (e = document.body, t = "complete") => {
    let n;
    const o = new Promise(e => n = e),
      s = () => {
        m().then(() => a(e, n));
      };
    if (d(t)) s();else {
      const e = "readystatechange",
        n = () => {
          d(t) && (document.removeEventListener(e, n), s());
        };
      document.addEventListener(e, n);
    }
    return o;
  },
  d = e => c[document.readyState] >= c[e],
  m = () => {
    if (document.porscheDesignSystem?.["3.20.0"]?.isReady) return document.porscheDesignSystem["3.20.0"].isReady();
    {
      let e;
      const t = new Promise(t => e = t),
        n = {
          set(t, n, o) {
            return "3.20.0" === n && o.isReady().then(e), Reflect.set(...arguments);
          }
        };
      return document.porscheDesignSystem = new Proxy(document.porscheDesignSystem || {}, n), t;
    }
  },
  a = (e, t) => {
    const n = u(e);
    Promise.all(n).then(e => t(e.length)).catch(e => console.error("[Porsche Design System]", e));
  },
  u = e => {
    const t = [],
      n = [e];
    for (; n.length > 0;) {
      const e = n.pop();
      e.nodeType === Node.ELEMENT_NODE && (l(e) && t.push(e.componentOnReady()), n.push(...Array.from(e.children)));
    }
    return t;
  },
  p = /^(.*-)?P-(.*)$/,
  l = e => p.test(e.tagName) && "function" == typeof e.componentOnReady;
var f = t.O,
  y = t.H;
export { f as componentsReady, y as load };